import React, { useReducer, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { X, ChevronDown, ChevronLeft, ChevronRight } from 'react-feather';
import Link from '@mui/material/Link';

import { TokenRow } from '@components/modals/selectors/token/TokenRow';
import { Overlay } from '@components/modals/Overlay';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { ZKP2P_TG_LINK } from '@helpers/docUrls';
import { colors } from '@theme/colors';
import { ThemedText } from '@theme/text'
import { Z_INDEX } from '@theme/zIndex';
import { tokens, tokenInfo, TokenType } from '@helpers/types/tokens';

interface TokenSelectorProps {
  selectedToken: TokenType;
  setSelectedToken: (token: TokenType) => void;
  onlyShowCurrentNetwork?: boolean;
  onlyShowDepositAllowedTokens?: boolean;
  stopSelection?: boolean;
}

export const TokenSelector: React.FC<TokenSelectorProps> = ({
  selectedToken,
  setSelectedToken,
  onlyShowCurrentNetwork = false,
  onlyShowDepositAllowedTokens = false,
  stopSelection = false,
}) => {
  const [isOpen, toggleOpen] = useReducer((s) => !s, false)
  const [selectedChain, setSelectedChain] = useState<string>(tokenInfo[selectedToken].chainName)
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);

  useEffect(() => {
    setSelectedChain(tokenInfo[selectedToken].chainName);
  }, [selectedToken]);

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined)

  const filteredTokens = tokens.filter((token) => {
    if (onlyShowCurrentNetwork && !tokenInfo[token].isNative) return false;
    if (onlyShowDepositAllowedTokens && !tokenInfo[token].depositAllowed) return false;
    if (tokenInfo[token].chainName !== selectedChain) return false;
    return true;
  });

  const chains = Array.from(new Set(tokens.map(token => tokenInfo[token].chainName)));

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = () => {
    toggleOpen();
  };

  const handleSelectToken = (token: TokenType) => {
    if (setSelectedToken && !stopSelection) {
      setSelectedToken(token);
      toggleOpen();
    }
  };

  // Simplified scroll handlers
  const scrollToStart = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
      setShowLeftScroll(false);
      setShowRightScroll(true);
    }
  };

  const scrollToEnd = () => {
    if (scrollContainerRef.current) {
      const { scrollWidth, clientWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollTo({ 
        left: scrollWidth - clientWidth, 
        behavior: 'smooth' 
      });
      setShowLeftScroll(true);
      setShowRightScroll(false);
    }
  };

  return (
    <Wrapper ref={ref}>
      <TokenNameAndChevronContainer onClick={stopSelection ? undefined : toggleOpen}>
        <TokenIconContainer>
          <TokenSvg src={tokenInfo[selectedToken].icon}/>
          <ChainIconWrapper>
            <ChainSvg src={tokenInfo[selectedToken].chainIcon} />
          </ChainIconWrapper>
        </TokenIconContainer>
        
        <TokenLabel>
          {tokenInfo[selectedToken].ticker}
        </TokenLabel>

        <StyledChevronDown/>
      </TokenNameAndChevronContainer>

      {isOpen && (
        <ModalAndOverlayContainer>
          <Overlay onClick={handleOverlayClick}/>

          <ModalContainer>
            <TableHeader>
              <ThemedText.SubHeader style={{ textAlign: 'left' }}>
                Select a Token
              </ThemedText.SubHeader>

              <button
                onClick={handleOverlayClick}
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              >
                <StyledX/>
              </button>
            </TableHeader>

            <HorizontalDivider/>

            {!onlyShowCurrentNetwork && !onlyShowDepositAllowedTokens && (
              <ChainSelectorWrapper>
                {showLeftScroll && (
                  <ScrollButton onClick={scrollToStart} direction="left">
                    <ChevronLeft size={20} />
                  </ScrollButton>
                )}
                
                <ChainSelectorContainer ref={scrollContainerRef}>
                  {chains.map((chain) => (
                    <ChainButton
                      key={chain}
                      selected={selectedChain === chain}
                      onClick={() => setSelectedChain(chain)}
                    >
                      {chain}
                    </ChainButton>
                  ))}
                </ChainSelectorContainer>

                {showRightScroll && (
                  <ScrollButton onClick={scrollToEnd} direction="right">
                    <ChevronRight size={20} />
                  </ScrollButton>
                )}
              </ChainSelectorWrapper>
            )}

            <Table>
              {filteredTokens.map((token, tokenIndex) => (
                <TokenRow
                  key={tokenIndex}
                  token={token}
                  tokenSvg={tokenInfo[token].icon}
                  isSelected={token === selectedToken}
                  onRowClick={() => handleSelectToken(token)}
                />
              ))}
            </Table>

            <HorizontalDivider/>

            <TableFooter>
              Let us know which tokens you are interested in seeing ZKP2P add support
              for. <Link href={ ZKP2P_TG_LINK } target='_blank'>
                Give feedback ↗
              </Link>
            </TableFooter>
          </ModalContainer>
        </ModalAndOverlayContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TokenIconContainer = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;

const TokenSvg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const ChainIconWrapper = styled.div`
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1E2230;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1E2230;
`;

const ChainSvg = styled.img`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const TokenNameAndChevronContainer = styled.div`
  min-width: 98px;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  background: ${colors.selectorColor};
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 4px 6px 4px 4px;
  gap: 6px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.selectorHover};
    border: 1px solid ${colors.selectorHoverBorder};
  }
`;

const TokenLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #FFF;
  padding-top: 2px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80px;
`;

const StyledChevronDown = styled(ChevronDown)`
  min-width: 20px;
  width: 20px;
  height: 20px;
  color: #FFF;
  flex-shrink: 0;
`;

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const ModalContainer = styled.div`
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: ${colors.container};
  color: #FFF;
  align-items: center;
  z-index: 20;
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TableHeader = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px 16px 20px;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
`;

const StyledX = styled(X)`
  color: #FFF;
`;

const Table = styled.div`
  width: 100%;
  color: #616161;
  overflow-y: auto;
  scrollbar-width: thin;
  height: 32vh;
`;

const TableFooter = styled.div`
  padding: 20px;
  font-size: 14px;
  text-align: left;
  line-height: 1.5;
  color: ${colors.grayText};
`;

const ChainSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 4px;
  position: relative;
`;

const ScrollButton = styled.button<{ direction: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.container};
  border: none;
  border-radius: 8px;
  width: 24px;
  height: 32px;
  cursor: pointer;
  z-index: 1;
  color: white;
  padding: 0;
  margin: 0 4px;
  
  &:hover {
    background: ${colors.selectorHover};
  }

  ${props => props.direction === 'left' ? `
    margin-right: -12px;
  ` : `
    margin-left: -12px;
  `}
`;

const ChainSelectorContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 24px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    display: none;
  }

  // Prevent scroll chaining
  overscroll-behavior: contain;
`;

const ChainButton = styled.button<{ selected: boolean }>`
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid ${colors.defaultBorderColor};
  background: ${props => props.selected ? colors.iconButtonActive : colors.selectorColor};
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.2s ease;
  flex-shrink: 0;

  &:hover {
    background: ${props => props.selected ? colors.iconButtonActive : colors.iconButtonHover};
    border-color: ${colors.selectorHoverBorder};
  }
`;