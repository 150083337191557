import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { X } from 'react-feather';
import { ENSName, AddressDisplayEnum } from 'react-ens-name';

import { Button } from '@components/common/Button';
import { Overlay } from '@components/modals/Overlay';
import { CopyButton } from '@components/common/CopyButton';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useQuery from '@hooks/useQuery';
import { alchemyMainnetEthersProvider } from 'index';
import { tokenInfo, TokenType } from '@helpers/types';
import { SOLANA_CHAIN_ID } from '@helpers/constants';

interface IntegrationProps {
  onBackClick: () => void;
}

export const IntegrationModal: React.FC<IntegrationProps> = ({ onBackClick }) => {
  const { queryParams } = useQuery();

  /*
   * Handlers
   */
  const handleOverlayClick = () => {
    onBackClick();
  };

  /*
   * Helpers
   */

  const networkName = (): string => {
    const toToken = queryParams.REFERRER_TO_TOKEN;
    if (!toToken || !tokenInfo[toToken as TokenType]) return 'Base';
    return tokenInfo[toToken as TokenType].chainName || 'Base';
  };

  const instructionCopy = (): string => {
    const { REFERRER, REFERRER_TO_TOKEN, REFERRER_RECIPIENT_ADDRESS } = queryParams;
    const tokenTicker =
      REFERRER_TO_TOKEN && tokenInfo[REFERRER_TO_TOKEN as TokenType]
        ? tokenInfo[REFERRER_TO_TOKEN as TokenType].ticker
        : 'USDC';
    if (!REFERRER_RECIPIENT_ADDRESS) {
      return `You've arrived from ${REFERRER}. Complete the Swap to receive ${tokenTicker}.`;
    }
    return `You've arrived from ${REFERRER}. Complete the Swap to receive ${tokenTicker} directly to the following wallet address:`;
  };

  const renderLogos = () => {
    const referrer = queryParams.REFERRER;
    const referrerLogo = queryParams.REFERRER_LOGO;

    if (!referrerLogo) {
      return (
        <StyledLogo>
          <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="ZKP2P logo" />
        </StyledLogo>
      );
    }

    return (
      <LogosContainer>
        <StyledLogo>
          <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="ZKP2P logo" />
        </StyledLogo>
        <CrossIcon>×</CrossIcon>
        <StyledLogo>
          <img src={referrerLogo} alt={`${referrer}`} />
        </StyledLogo>
      </LogosContainer>
    );
  };

  const renderIntegrationBenefits = () => (
    <BenefitsContainer>
      <BenefitItem>
        <ThemedText.SubHeaderSmall>🔒 Secure</ThemedText.SubHeaderSmall>
        <ThemedText.BodySmall style={{ color: colors.grayText }}>
          Onramp with zero counterparty risk
        </ThemedText.BodySmall>
      </BenefitItem>
      <BenefitItem>
        <ThemedText.SubHeaderSmall>💸 Best Rates</ThemedText.SubHeaderSmall>
        <ThemedText.BodySmall style={{ color: colors.grayText }}>
          No intermediaries, transact directly with a seller
        </ThemedText.BodySmall>
      </BenefitItem>
      <BenefitItem>
        <ThemedText.SubHeaderSmall>⚡ Fast Settlement</ThemedText.SubHeaderSmall>
        <ThemedText.BodySmall style={{ color: colors.grayText }}>
          Verify payment to instantly unlock crypto to your wallet
        </ThemedText.BodySmall>
      </BenefitItem>
    </BenefitsContainer>
  );

  return (
    <ModalAndOverlayContainer>
      <Overlay />

      <ModalContainer>
        <TitleCenteredRow>
          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Welcome to ZKP2P
          </ThemedText.HeadlineSmall>
        </TitleCenteredRow>

        {renderLogos()}

        <InstructionAndAddressContainer>
          <BenefitsContainer>{renderIntegrationBenefits()}</BenefitsContainer>
          <InstructionsContainer>
            <ThemedText.SubHeader style={{ flex: '1', margin: 'auto', textAlign: 'left' }}>
              {instructionCopy()}
            </ThemedText.SubHeader>
          </InstructionsContainer>

          {queryParams.REFERRER_RECIPIENT_ADDRESS && (
            <AccountAddressContainer>
              <ThemedText.LabelSmall style={{ textAlign: 'left', color: '#FFF' }}>
                Recipient Address ({networkName()})
              </ThemedText.LabelSmall>

              <AddressRow>
                <AddressLabel>
                  {queryParams.REFERRER_TO_TOKEN && tokenInfo[queryParams.REFERRER_TO_TOKEN as TokenType].networkChainId === SOLANA_CHAIN_ID ? (
                    <SolanaAddressLabel>
                      {queryParams.REFERRER_RECIPIENT_ADDRESS}
                    </SolanaAddressLabel>
                  ) : (
                    <ENSName
                      provider={alchemyMainnetEthersProvider}
                      address={queryParams.REFERRER_RECIPIENT_ADDRESS || ''}
                      displayType={AddressDisplayEnum.FULL}
                    />
                  )}
                </AddressLabel>
                <CopyButton textToCopy={queryParams.REFERRER_RECIPIENT_ADDRESS || ''} />
              </AddressRow>
            </AccountAddressContainer>
          )}
        </InstructionAndAddressContainer>
        
        <Button 
          onClick={onBackClick} 
          width={164}
        >
          Continue
        </Button>
      </ModalContainer>
    </ModalAndOverlayContainer>
  );
};

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: 10;
`;

const ModalContainer = styled.div`
  width: 80vw;
  max-width: 392px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 1.25rem;
  background-color: ${colors.container};
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  gap: 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TitleCenteredRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

const StyledLogo = styled.div<{ isReferrer?: boolean }>`
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #fff;
  text-decoration: none;

  img {
    width: 92px;
    height: 92px;
    object-fit: cover;
  }
`;

const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const CrossIcon = styled.span`
  color: ${colors.white};
  font-size: 2rem;
`;

const InstructionAndAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  align-items: center;
  color: #fff;
`;

const InstructionsContainer = styled.div`
  padding: 0 1.25rem;
`;

const AccountAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.25rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
  width: 100%;
  box-sizing: border-box;
`;

const AddressRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AddressLabel = styled.div`
  max-width: calc(100% - 16px);
  font-size: 16px;
  word-break: break-all;
  line-height: 1.4;
`;

const SolanaAddressLabel = styled.div`
  max-width: calc(100% - 16px);
  font-size: 16px;
  word-break: break-all;
  line-height: 1.4;
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0 1.25rem;
`;

const BenefitItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export default IntegrationModal;