import React from 'react';
import Link from '@mui/material/Link';
import { ENSName, AddressDisplayEnum } from 'react-ens-name';
import { alchemyMainnetEthersProvider } from 'index';
import { SOLANA_CHAIN_ID } from '@helpers/constants';
import { TokenType, tokenInfo } from '@helpers/types';

interface RecipientAddressDisplayProps {
  recipientAddress: string;
  token?: TokenType;
  defaultBlockExplorerUrl?: string;
  displayType?: AddressDisplayEnum;
}

/**
 * A reusable component for displaying recipient address details
 * @returns An object with label and value properties
 */
export const getRecipientAddressDisplay = ({
  recipientAddress,
  token,
  defaultBlockExplorerUrl = 'https://basescan.org',
  displayType = AddressDisplayEnum.FIRST6,
}: RecipientAddressDisplayProps) => {
  if (!recipientAddress) {
    return null;
  }

  // Get the block explorer URL and chain info based on the token
  let blockExplorerUrl = defaultBlockExplorerUrl;
  let isSolana = false;
  let chainName = 'Base';
  
  if (token && tokenInfo[token]) {
    blockExplorerUrl = tokenInfo[token].blockExplorerUrl;
    isSolana = tokenInfo[token].networkChainId === SOLANA_CHAIN_ID;
    chainName = tokenInfo[token].chainName;
  }

  // Determine label based on chain
  const label = `Recipient${chainName ? ` (${chainName})` : ' Address'}`;
  
  // For Solana addresses, we just show the first few characters
  if (isSolana) {
    return {
      label,
      value: (
        <Link href={`${blockExplorerUrl}/address/${recipientAddress}`} target="_blank">
          {recipientAddress.slice(0, 8)}
        </Link>
      )
    };
  }
  
  // For other chains, use ENSName component
  return {
    label,
    value: (
      <Link href={`${blockExplorerUrl}/address/${recipientAddress}`} target="_blank">
        <ENSName
          provider={alchemyMainnetEthersProvider}
          address={recipientAddress}
          displayType={displayType}
        />
      </Link>
    )
  };
}; 