import React from "react";
import ReactDOM from "react-dom";
import merge from 'lodash.merge';
import { ethers } from 'ethers';
import { Connection } from "@solana/web3.js";
import { PrivyProvider } from '@privy-io/react-auth';
import { ZeroDevPrivyWagmiProvider } from '@zerodev/wagmi/privy';
import {
  WagmiConfig,
  createConfig,
  configureChains,
} from "wagmi";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { sepolia, base, hardhat } from 'wagmi/chains'
import {
  RainbowKitProvider,
  darkTheme,
  getDefaultWallets,
} from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { createClient, convertViemChainToRelayChain, MAINNET_RELAY_API } from '@reservoir0x/relay-sdk';
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';

import "./index.css";
import ReactErrorBoundary from './ErrorBoundary';
import App from "./App";

const getDefaultChain = (env) => {
  if (env === 'STAGING' || env === 'PRODUCTION') {
    return base;
  } else if (env === 'STAGING_TESTNET') {
    return sepolia;
  } else {
    return hardhat;
  }
};

const getChainsForEnvironment = (env) => {
  if (env === 'STAGING' || env === 'PRODUCTION') {
    return [base];
  } else if (env === 'STAGING_TESTNET') {
    return [sepolia];
  } else {
    return [hardhat];
  }
};

const env = process.env.DEPLOYMENT_ENVIRONMENT;
const chains = getChainsForEnvironment(env);
const configureChainsConfig = configureChains(
  chains,
  [
    // jsonRpcProvider({
    //   rpc: (chain) => ({
    //     http: `https://api.developer.coinbase.com/rpc/v1/base/${process.env.COINBASE_DEV_API_KEY}`
    //   })
    // }),
    alchemyProvider({ apiKey: process.env.ALCHEMY_API_KEY }),
    publicProvider()
  ]
);

export const alchemyMainnetEthersProvider =
  new ethers.providers.AlchemyProvider('mainnet', process.env.ALCHEMY_API_KEY);

const alchemySolanaEndpoint = `https://solana-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_SOLANA_API_KEY}`;
export const alchemySolanaConnection = new Connection(alchemySolanaEndpoint, 'confirmed');

const { connectors } = getDefaultWallets({
  appName: 'ZK P2P On-Ramp',
  projectId: process.env.WALLET_CONNECT_PROJECT_ID,
  chains
});

const config = createConfig({
  autoConnect: false,
  connectors,
  publicClient: configureChainsConfig.publicClient
})

const zkp2pTheme = merge(darkTheme(), {
  colors: {
    accentColor: '#df2e2d',
  },
  radii: {
    connectButton: '20px',
  },
  fonts: {
    body: 'Graphik',
  },
  shadows: {
    connectButton: 'inset 0px -4px 0px rgba(0, 0, 0, 0.16)',
  }
});

const zeroDevOptions = {
  projectIds: [process.env.ZERODEV_APP_ID],
  projectId: process.env.ZERODEV_APP_ID,
  useSmartWalletForExternalEOA: false, // Only sponsor gas for embedded wallets
}

createClient({
  baseApiUrl: MAINNET_RELAY_API,
  chains: chains.map(chain => convertViemChainToRelayChain(chain))
});

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  environment: process.env.DEPLOYMENT_ENVIRONMENT,
  autoInstrument: {
    log: false,
    network: false,
    dom: false,
    navigation: false,
  }
};

ReactDOM.render(
  <React.StrictMode>
    <ReactErrorBoundary>
      <RollbarProvider config={rollbarConfig}>
        <RollbarErrorBoundary>
          <WagmiConfig config={config}>
            <RainbowKitProvider chains={chains} theme={zkp2pTheme}>
              <PrivyProvider
                appId={process.env.PRIVY_APP_ID}
                config={{
                  embeddedWallets: {
                    createOnLogin: 'users-without-wallets',
                    noPromptOnSignature: true
                  },
                  loginMethodsAndOrder: {
                    primary: ['email', 'google', 'twitter', 'coinbase_wallet'],
                    overflow: ['metamask', 'rabby_wallet', 'rainbow', 'phantom', 'detected_wallets']
                  },
                  appearance: {
                    theme: "#0E111C",
                    accentColor: "#df2e2d",
                  },
                  externalWallets: {
                    coinbaseWallet: {
                      connectionOptions: 'smartWalletOnly',
                    },
                  },
                  defaultChain: getDefaultChain(env),
                  supportedChains: chains
                }}
              >
                <ZeroDevPrivyWagmiProvider wagmiChainsConfig={configureChainsConfig} options={zeroDevOptions}>
                  <App />
                </ZeroDevPrivyWagmiProvider>
              </PrivyProvider>
            </RainbowKitProvider>
          </WagmiConfig>
        </RollbarErrorBoundary>
      </RollbarProvider>
    </ReactErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
