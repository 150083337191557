import React, { useEffect, useMemo, useState, ChangeEvent, useCallback, useRef } from "react";
import styled from 'styled-components';
import { BigNumber } from 'ethers';
import { ThemedText } from '@theme/text';
import { colors, opacify } from '@theme/colors';


import { Button } from '@components/common/Button';
import { AutoColumn } from '@components/layouts/Column';
import { CustomConnectButton } from "@components/common/ConnectButton";

import Spinner  from '@components/common/Spinner';
import { SettingsDropdown } from './SettingsDropdown';
import { OnRamperIntent } from '@components/Swap/OnRamperIntent';
import { InputWithCurrencySelector } from "@components/modals/selectors/currency";
import { InputWithTokenSelector } from "@components/modals/selectors/token/InputWithTokenSelector";
import { LabelWithPlatformSelector } from '@components/modals/selectors/platform';
import { AccessoryButton } from '@components/common/AccessoryButton';
import { Input } from "@components/common/Input";
import { IntegrationModal } from '@components/modals/IntegrationModal';

import { QUOTE_DEFAULT_SOL_ADDRESS, QUOTE_DEFAULT_ADDRESS, ZERO, POLYGON_CHAIN_ID, SONIC_CHAIN_ID } from '@helpers/constants';
import { BASE_USDC_ADDRESS, BASE_CHAIN_ID, SOLANA_CHAIN_ID, ETH_CHAIN_ID} from '@helpers/constants';
import { Address, getCurrencyInfoFromHash, LoginStatus, Token, tokenInfo, TokenType } from "@helpers/types";
import { etherUnitsToReadable, tokenUnits, tokenUnitsToReadable } from '@helpers/units';
import { Currency, CurrencyType, currencyInfo } from "@helpers/types";
import { PaymentPlatform, paymentPlatformInfo, PaymentPlatformType, paymentPlatforms}  from "@helpers/types";
import { QuoteState, QuoteStateType } from "@helpers/types/transactions/quoteStatus";

import useSignalIntent from "@hooks/transactions/useSignalIntent";
import useSignIntent from "@hooks/backend/useSignIntent";
import useQuoteMaxTokenForExactFiat from "@hooks/backend/useQuoteMaxTokenForFiat";

import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useBackend from '@hooks/contexts/useBackend';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useOnRamperIntents from "@hooks/contexts/useOnRamperIntents";
import useMediaQuery from "@hooks/useMediaQuery";
import useRelayBridge from '@hooks/useRelayBridge';
import { GetPriceParameters } from "@reservoir0x/relay-sdk";
import useQuery from "@hooks/useQuery";
import { QuoteDetails } from "./QuoteDetails";
import useLocalStorage from '@hooks/useLocalStorage';
import useGeolocation from '@hooks/contexts/useGeolocation';
import { isSupportedCurrency } from "@helpers/types/currency";
import useSessionStorage from '@hooks/useSessionStorage';
import { WarningTextBox } from "@components/common/WarningTextBox";
import { commonStrings } from "@helpers/strings";
import { OnRamperIntentInfo } from "./OnRamperIntentInfo";
import useQuoteMinFiatForToken from "@hooks/backend/useQuoteMinFiatForToken";
import ReferrerInfo from './ReferrerInfo';

type SwapQuote = {
  depositId: number;
  hashedOnchainId: string;
  fiatAmount: BigNumber;
  usdcAmount: BigNumber;
  usdcToFiatRate: string;
  outputTokenAmount: BigNumber;
  outputTokenDecimals: number;
  outputTokenAmountInUsd?: string;
  gasFeesInUsd?: string;
  appFeeInUsd?: string;
  relayerFeeInUsd?: string;
  relayerGasFeesInUsd?: string;
  relayerServiceFeesInUsd?: string;
  usdcToTokenRate?: string;
  timeEstimate?: string;
};

const ZERO_QUOTE: SwapQuote = {
  depositId: 0,
  hashedOnchainId: '',
  fiatAmount: ZERO,
  usdcAmount: ZERO,
  usdcToFiatRate: '',
  outputTokenAmount: ZERO,
  outputTokenDecimals: 18,
};


interface SwapFormProps {
  onCompleteOrderClick: () => void;
}


const SwapForm: React.FC<SwapFormProps> = ({
  onCompleteOrderClick,
}: SwapFormProps) => {

  /*
   * Contexts
   */


  const currentDeviceSize = useMediaQuery();
  const isMobile = currentDeviceSize === 'mobile';

  const { usdcBalance } = useBalances();
  const { isLoggedIn, loggedInEthereumAddress, loginStatus } = useAccount();
  const { platformToVerifierAddress, usdcAddress, chainId } = useSmartContracts();
  const { currentIntentHash, refetchIntentView } = useOnRamperIntents();
  const { fetchPayeeDetails } = useBackend();
  const { getRelayPrice } = useRelayBridge();

  const { queryParams, updateQueryParams, clearReferrerQueryParams } = useQuery();
  const { currencyCode } = useGeolocation();

  /*
   * Helper function to validate initial values
   */
  const getInitialValues = () => {
    const referrer = queryParams.REFERRER;
    const amountFromQuery = queryParams.REFERRER_INPUT_AMOUNT;
    const currencyFromQuery = queryParams.REFERRER_FROM_CURRENCY as CurrencyType;
    const platformFromQuery = queryParams.REFERRER_PAYMENT_PLATFORM as PaymentPlatformType;
    const tokenFromQuery = queryParams.REFERRER_TO_TOKEN as string;
    const recipientAddressFromQuery = queryParams.REFERRER_RECIPIENT_ADDRESS;
    const amountUsdcFromQuery = queryParams.REFERRER_AMOUNT_USDC;

    const initialValues = {
      fiatAmount: amountFromQuery && isValidFiatInput(amountFromQuery) ? amountFromQuery : '',
      fiatCurrency: isSupportedCurrency(currencyFromQuery) ? currencyFromQuery : (
        currencyCode && isSupportedCurrency(currencyCode) ? currencyCode : 'USD'  // order of priority, currencyFromQuery, geographical currencyCode, USD
      ),
      paymentPlatform: platformFromQuery && Object.values(PaymentPlatform).includes(platformFromQuery) 
        ? platformFromQuery 
        : PaymentPlatform.VENMO,
      token: amountUsdcFromQuery ? Token.USDC : (
        tokenFromQuery && Object.values(Token).includes(tokenFromQuery) ? tokenFromQuery : Token.USDC
      ),
      recipientAddress: recipientAddressFromQuery || '',
      showRecipientInput: !!recipientAddressFromQuery,
      showIntegrationModal: !!referrer,
      usdcAmount: amountUsdcFromQuery ? tokenUnitsToReadable(amountUsdcFromQuery, 6, 2) : '',
      isUsdcAmountFromQuery: !!amountUsdcFromQuery && isValidUsdcInput(amountUsdcFromQuery),
      isExactOutput: !!amountUsdcFromQuery 
        && isValidUsdcInput(amountUsdcFromQuery) 
        && !!recipientAddressFromQuery 
        && isValidAddress(recipientAddressFromQuery, BASE_CHAIN_ID),
    };

    return initialValues;
  };

  const initialValues = getInitialValues();

  /*
   * State
   */
  const [quoteState, setQuoteState] = useState<QuoteStateType>(QuoteState.DEFAULT);
  const [currentQuote, setCurrentQuote] = useState<SwapQuote>(ZERO_QUOTE);
  
  const [fiatAmount, setFiatAmount] = useSessionStorage<string>(
    'lastUsedFiatAmount', 
    initialValues.fiatAmount
  );
  const [fiatCurrency, setFiatCurrency] = useSessionStorage<CurrencyType>(
    'lastUsedFiatCurrency',
    initialValues.fiatCurrency
  );
  const [paymentPlatform, setPaymentPlatform] = useLocalStorage<PaymentPlatformType>(
    'lastUsedPaymentPlatform', 
    initialValues.paymentPlatform
  );
  const [allCurrencyPlatforms, setAllCurrencyPlatforms] = useState<PaymentPlatformType[]>(paymentPlatforms);
  const [token, setToken] = useSessionStorage<string>('token', initialValues.token);

  const [recipientAddress, setRecipientAddress] = useSessionStorage<string>(
    'recipientAddress', 
    initialValues.recipientAddress
  );
  const [showRecipientInput, setShowRecipientInput] = useSessionStorage<boolean>(
    'showRecipientInput', 
    initialValues.showRecipientInput
  );

  const [formattedTokenAmount, setFormattedTokenAmount] = useState<string>(
    initialValues.usdcAmount
  );
  const [formattedTokenAmountInUsd, setFormattedTokenAmountInUsd] = useState<string>(
    initialValues.usdcAmount
  );

  const [showMobileWarning, setShowMobileWarning] = useSessionStorage<boolean>('showMobileWarning', true);

  const [showIntegrationModal, setShowIntegrationModal] = useSessionStorage<boolean>(
    'showIntegrationModal', 
    initialValues.showIntegrationModal
  );
  
  const [isExactOutput, setIsExactOutput] = useSessionStorage<boolean>(
    'isExactOutput',
    initialValues.isExactOutput
  );
  /*
   * Contract Writes
   */

  const onSignalIntentSuccessCallback = useCallback(async (data: any) => {
    console.log('writeSignalIntentAsync successful: ', data);
    setShouldConfigureSignalIntentWrite(false);
    
    // Fetch raw payee details
    fetchPayeeDetails(currentQuote.hashedOnchainId, paymentPlatform);

    setCurrentQuote(ZERO_QUOTE);
    setFiatAmount('');
    
    onCompleteOrderClick();

    refetchIntentView?.();
  }, [refetchIntentView, paymentPlatform, currentQuote.hashedOnchainId]);

  const onSignalIntentFailedCallback = useCallback(async (data: any) => {
    console.log('writeSignalIntentAsync failed: ', data);

    refetchIntentView?.();
  }, [refetchIntentView]);

  const {
    writeSignalIntentAsync,
    setDepositIdInput,
    setTokenAmountInput,
    recipientAddressInput,
    setRecipientAddressInput,
    setVerifierAddressInput,
    setCurrencyCodeHashInput,
    setGatingServiceSignatureInput,
    shouldConfigureSignalIntentWrite,
    setShouldConfigureSignalIntentWrite,
    signSignalIntentTransactionStatus,
    mineSignalIntentTransactionStatus
  } = useSignalIntent(
    onSignalIntentSuccessCallback, 
    onSignalIntentFailedCallback
  );

  /*
   * Fetch Quote from Backend
   */

  const {
    data: quoteResponse,
    isLoading: isFetchingQuote,
    error: quoteError,
    fetchQuote: quoteMaxTokenForExactFiat
  } = useQuoteMaxTokenForExactFiat();

  const {
    data: quoteMinFiatForTokenResponse,
    isLoading: isFetchingQuoteMinFiatForToken,
    error: quoteMinFiatForTokenError,
    fetchQuote: quoteMinFiatForToken
  } = useQuoteMinFiatForToken();

  useEffect(() => {
    if (quoteResponse) {
      console.log("quoteResponse success: ", quoteResponse);

      const { quotes } = quoteResponse.responseObject;

      // cheoose the quote 0 for now
      const quote = quotes[0];
      setDepositIdInput(Number(quote.intent.depositId));
      setTokenAmountInput(quote.tokenAmount.toString());

      const conversionRate = etherUnitsToReadable(BigNumber.from(quote.conversionRate), 4);
      
      // Create a new quote object to avoid partial updates
      const newQuote = {
        ...ZERO_QUOTE,
        usdcAmount: BigNumber.from(quote.tokenAmount),
        depositId: Number(quote.intent.depositId),
        hashedOnchainId: quote.intent.payeeDetails,
        usdcToFiatRate: conversionRate,
      };
      
      // If user is receiving USDC -> we are done
      if (token === Token.USDC) {
        setCurrentQuote(newQuote);
        setQuoteState(QuoteState.FETCH_QUOTE_SUCCESS);
      } else {
        // Non-USDC quotes that require a conversion
        const doConversion = async () => {
          try {
            const originChainId = BASE_CHAIN_ID;
            const destinationChainId = tokenInfo[token].networkChainId;
            const params: GetPriceParameters = {
              user: loggedInEthereumAddress as Address || QUOTE_DEFAULT_ADDRESS,
              recipient: tokenInfo[token].networkChainId === SOLANA_CHAIN_ID ? QUOTE_DEFAULT_SOL_ADDRESS as any : QUOTE_DEFAULT_ADDRESS,
              originChainId,
              destinationChainId,
              originCurrency: BASE_USDC_ADDRESS,
              destinationCurrency: tokenInfo[token].address,
              amount: quote.tokenAmount.toString(),
              tradeType: 'EXACT_INPUT',
            };
  
            const relayResult = await getRelayPrice(params);
            console.log("relayResult:", relayResult);
  
            const outAmount = relayResult?.details?.currencyOut?.amount ?? '0';
            
            // Set the complete quote object at once
            setCurrentQuote({
              ...newQuote,
              outputTokenAmount: BigNumber.from(outAmount),
              outputTokenDecimals: tokenInfo[token].tokenDecimals,
              outputTokenAmountInUsd: Number(relayResult?.details?.currencyOut?.amountUsd).toFixed(2),
              gasFeesInUsd: Number(relayResult?.fees?.gas?.amountUsd).toFixed(4),
              appFeeInUsd: Number(relayResult?.fees?.app?.amountUsd).toFixed(4),
              relayerFeeInUsd: Number(relayResult?.fees?.relayer?.amountUsd).toFixed(4),
              relayerGasFeesInUsd: Number(relayResult?.fees?.relayerGas?.amountUsd).toFixed(4),
              relayerServiceFeesInUsd: Number(relayResult?.fees?.relayerService?.amountUsd).toFixed(4),
              usdcToTokenRate: relayResult?.details?.rate,
              timeEstimate: relayResult?.details?.timeEstimate?.toString(),
            });

            // Validate address before returning successful quote
            const isRecipientValid = isValidAddress(recipientAddress, tokenInfo[token].networkChainId);
            if (!isRecipientValid) {
              updateQuoteErrorState(QuoteState.INVALID_RECIPIENT_ADDRESS);
              return;
            }

            setQuoteState(QuoteState.FETCH_QUOTE_SUCCESS);
          } catch (err) {
            console.error("Aggregator conversion error:", err);
            setCurrentQuote(ZERO_QUOTE);
            setQuoteState(QuoteState.FAILED_TO_FETCH_QUOTE);
          }
        };
        doConversion();
      }
    }
  }, [quoteResponse, token, getRelayPrice, usdcAddress]);


  useEffect(() => {

    if (currentIntentHash) {
      updateQuoteErrorState(QuoteState.EXCEEDS_ORDER_COUNT);
      return;
    }

    if (quoteMinFiatForTokenResponse) {
      console.log("quoteMinFiatForTokenResponse success: ", quoteMinFiatForTokenResponse);

      const { quotes } = quoteMinFiatForTokenResponse.responseObject;
      const quote = quotes[0];
      const usdcAmount = quote.tokenAmount.toString();
      const usdcToFiatRate = etherUnitsToReadable(BigNumber.from(quote.conversionRate), 4);

      setDepositIdInput(Number(quote.intent.depositId));
      setFiatAmount(tokenUnitsToReadable(quote.fiatAmount, 6, 2));
      setTokenAmountInput(usdcAmount.toString());

      const newQuote = {
        ...ZERO_QUOTE,
        fiatAmount: BigNumber.from(quote.fiatAmount),
        usdcAmount: BigNumber.from(usdcAmount),
        depositId: Number(quote.intent.depositId),
        hashedOnchainId: quote.intent.payeeDetails,
        usdcToFiatRate: usdcToFiatRate,
      };

      setCurrentQuote(newQuote);
      setQuoteState(QuoteState.FETCH_QUOTE_SUCCESS);
    }
  }, [quoteMinFiatForTokenResponse]);

  useEffect(() => {
    if (quoteError) {
      console.log("quoteError: ", quoteError);

      if (quoteError.message.includes('Too Many Requests')) {
        updateQuoteErrorState(QuoteState.TOO_MANY_REQUESTS_FAILED_TO_FETCH_QUOTE);
      } else {
        updateQuoteErrorState(QuoteState.FAILED_TO_FETCH_QUOTE);
      }
    }
  }, [quoteError]);

  useEffect(() => {
    if (currentIntentHash) {
      updateQuoteErrorState(QuoteState.EXCEEDS_ORDER_COUNT);
      return;
    }

    if (quoteMinFiatForTokenError) {
      setFiatAmount('');
      updateQuoteErrorState(QuoteState.FAILED_TO_FETCH_QUOTE);
    }
  }, [quoteMinFiatForTokenError]);

  /*
   * Fetch Signed Intent from Backend
   */

  const {
    data: signedIntentResponse,
    isLoading: isFetchingSignedIntent,
    error: signedIntentError,
    fetchSignedIntent
  } = useSignIntent();

  useEffect(() => {
    if (signedIntentResponse) {
      console.log("signedIntentResponse success: ", signedIntentResponse);

      // If user is swapping to another token on any chain (native or non-native), use the logged 
      // in ethereum address (embedded or injected) for receiving intermediary USDC
      const usdcRecipientAddress = token !== Token.USDC ? loggedInEthereumAddress : recipientAddress;

      setRecipientAddressInput(usdcRecipientAddress);
      setVerifierAddressInput(platformToVerifierAddress[paymentPlatform] ?? "");
      setCurrencyCodeHashInput(currencyInfo[fiatCurrency].currencyCodeHash);
      setGatingServiceSignatureInput(signedIntentResponse.responseObject.signedIntent);

      setShouldConfigureSignalIntentWrite(true);
    }
  }, [signedIntentResponse]);

  useEffect(() => {
    if (signedIntentError) {
      console.log("signedIntentError: ", signedIntentError);

      updateQuoteErrorState(QuoteState.FAILED_TO_FETCH_SIGNED_INTENT);
    }
  }, [signedIntentError]);

  /*
   * Hooks
   */

  useEffect(() => {
    if (currencyCode) {
      // Only set if there's no existing stored value
      const storedCurrency = window.sessionStorage.getItem('lastUsedFiatCurrency');
      if (!storedCurrency) {
        const currencyFromQuery = queryParams.REFERRER_FROM_CURRENCY;
        if (currencyFromQuery && isSupportedCurrency(currencyFromQuery)) {
          setFiatCurrency(currencyFromQuery);
        } else if (isSupportedCurrency(currencyCode)) {
          setFiatCurrency(currencyCode);
        } else {
          setFiatCurrency('USD');
        }
      }
    }
  }, [currencyCode]);

  useEffect(() => {
    const recipientAddressFromQuery = queryParams.REFERRER_RECIPIENT_ADDRESS;
    const recipientAddressIsValid = recipientAddressFromQuery && isValidOrEmptyAddress(recipientAddressFromQuery, tokenInfo[token].networkChainId);
    if (recipientAddressIsValid) {
      setRecipientAddress(recipientAddressFromQuery);
      setShowRecipientInput(true);
      return;
    }

    if (isLoggedIn && loggedInEthereumAddress) {
      // If user is receiving SOL/ETH/POLYGON/SONIC and is logged in using embedded wallet,
      // set the recipient address to empty string and show the recipient input field
      if (
        tokenInfo[token].networkChainId === SOLANA_CHAIN_ID ||
        (tokenInfo[token].networkChainId === ETH_CHAIN_ID && loginStatus === LoginStatus.AUTHENTICATED) ||
        (tokenInfo[token].networkChainId === POLYGON_CHAIN_ID && loginStatus === LoginStatus.AUTHENTICATED) ||
        (tokenInfo[token].networkChainId === SONIC_CHAIN_ID && loginStatus === LoginStatus.AUTHENTICATED)
      ) {
        setRecipientAddress('');
        setShowRecipientInput(true);
      } else {
        setRecipientAddress(loggedInEthereumAddress);
      }
    }
  }, [isLoggedIn, loggedInEthereumAddress, token]);


  useEffect(() => {
    const fetchUsdcQuote = async () => {
      const amountUsdcFromQuery = queryParams.REFERRER_AMOUNT_USDC;
      const amountUsdcIsValid = amountUsdcFromQuery && isValidUsdcInput(amountUsdcFromQuery);
      
      // Return early if any required condition is not met
      if (amountUsdcIsValid && paymentPlatform && usdcAddress && fiatCurrency) {
        try {
          setQuoteState(QuoteState.FETCHING_QUOTE);

          await quoteMinFiatForToken({
            paymentPlatforms: [paymentPlatform],
            fiatCurrency: fiatCurrency,
            user: loggedInEthereumAddress || QUOTE_DEFAULT_ADDRESS,
            recipient: recipientAddress || QUOTE_DEFAULT_ADDRESS,
            destinationChainId: Number(chainId),
            destinationToken: usdcAddress,
            exactTokenAmount: amountUsdcFromQuery
          });
        } catch (error) {
          console.error('Error fetching USDC quote:', error);
          // Reset flag if there's an error
          setQuoteState(QuoteState.FAILED_TO_FETCH_QUOTE);
        }
      }
    };
    
    if (isExactOutput && !currentIntentHash) {
      fetchUsdcQuote();
    }
  }, [
    paymentPlatform,
    usdcAddress,
    fiatCurrency,
    quoteMinFiatForToken,
    isExactOutput,
    currentIntentHash
  ]);

  useEffect(() => {
    const allCurrencyPlatforms = Object.values(paymentPlatformInfo).filter(platform => 
      platform.platformCurrencies.includes(fiatCurrency)
    ).map(platform => platform.platformId);

    if (allCurrencyPlatforms.length > 0) {
      // If current platform doesn't support this currency, switch to first supported platform
      if (!allCurrencyPlatforms.includes(paymentPlatform)) {
        setPaymentPlatform(allCurrencyPlatforms[0]);
      }
    }
    setAllCurrencyPlatforms(allCurrencyPlatforms);
  }, [fiatCurrency, paymentPlatform, setPaymentPlatform]);

  useEffect(() => {
    if (isMobile && !paymentPlatformInfo[paymentPlatform].supportsAppclip) {
      updateQuoteErrorState(QuoteState.PLATFORM_NOT_SUPPORTED_ON_MOBILE);
      setCurrentQuote(ZERO_QUOTE);
    }
  }, [paymentPlatform, isLoggedIn, loggedInEthereumAddress]);

  /*
   * Quote State
   */

  useEffect(() => {
    if (currentIntentHash) {
      updateQuoteErrorState(QuoteState.EXCEEDS_ORDER_COUNT);
      return;
    }
  }, [currentIntentHash]);

  useEffect(() => {
    if (currentIntentHash || isExactOutput) {
      return;
    }

    if (isMobile && !paymentPlatformInfo[paymentPlatform].supportsAppclip) {
      return;
    }

    if (fiatAmount === '' || fiatAmount === '0' || fiatAmount === '0.') {
      updateQuoteErrorState(QuoteState.DEFAULT);
      setCurrentQuote(ZERO_QUOTE);
      return;
    }

    const usdcDecimals = 6; // USDC Decimals
    const fiatBn = tokenUnits(fiatAmount, usdcDecimals)
    const minFiatBn = tokenUnits(paymentPlatformInfo[paymentPlatform].minFiatAmount, usdcDecimals);
    if (fiatBn.lt(minFiatBn)) {
      updateQuoteErrorState(QuoteState.AMOUNT_BELOW_TRANSFER_MIN);
      return;
    }

    // Allow recipient to be empty during indicative quote
    const isRecipientValidOrEmpty = isValidOrEmptyAddress(recipientAddress, tokenInfo[token].networkChainId);
    if (!isRecipientValidOrEmpty) {
      updateQuoteErrorState(QuoteState.INVALID_RECIPIENT_ADDRESS);
      return;
    }

    const fetchQuote = async () => {
      if (usdcAddress) {
        setCurrentQuote(prevState => ({
          ...prevState,
          usdcAmount: ZERO, // Set to 0 to indicate that we are fetching a new quote
          tokenAmount: ZERO,
          outputTokenAmount: ZERO,
          outputTokenAmountInUsd: ''
        }));

        setQuoteState(QuoteState.FETCHING_QUOTE);

        await quoteMaxTokenForExactFiat({
          paymentPlatforms: [paymentPlatform],
          fiatCurrency: fiatCurrency,
          user: loggedInEthereumAddress || QUOTE_DEFAULT_ADDRESS,
          recipient: recipientAddress || QUOTE_DEFAULT_ADDRESS,
          destinationChainId: Number(chainId),
          destinationToken: usdcAddress,
          exactFiatAmount: fiatBn.toString(),
        });
      }
    }

    fetchQuote();
  }, [
    fiatAmount,
    paymentPlatform,
    token,
    fiatCurrency,
    usdcAddress,
    currentIntentHash,
    recipientAddress,
    loggedInEthereumAddress,
    quoteMaxTokenForExactFiat,
  ]);

  useEffect(() => {
    const updateSignalIntentStatus = async () => {
      const successfulVerificationTransaction = mineSignalIntentTransactionStatus === 'success';
      if (successfulVerificationTransaction) {
        setQuoteState(QuoteState.DONE);
      } else {
        const signingSignalIntentTransaction = signSignalIntentTransactionStatus === 'loading';
        const miningSignalIntentTransaction = mineSignalIntentTransactionStatus === 'loading';

        if (signingSignalIntentTransaction) {
          setQuoteState(QuoteState.SIGNAL_INTENT_TRANSACTION_LOADING);
        } else if (miningSignalIntentTransaction) {
          setQuoteState(QuoteState.SIGNAL_INTENT_TRANSACTION_MINING);
        }
      }
    }

    updateSignalIntentStatus();
  }, [
      signSignalIntentTransactionStatus,
      mineSignalIntentTransactionStatus,
    ]
  );

  useEffect(() => {
    const executeSignalIntent = async () => {
      const statusForExecution = 
        signSignalIntentTransactionStatus === 'idle' || 
        signSignalIntentTransactionStatus === 'error' ||
        signSignalIntentTransactionStatus === 'success';

      if (shouldConfigureSignalIntentWrite && writeSignalIntentAsync && statusForExecution) {
        try {
          setShouldConfigureSignalIntentWrite(false);

          await writeSignalIntentAsync();
        } catch (error) {
          console.error('writeSignalIntentAsync failed: ', error);
          updateQuoteErrorState(QuoteState.SIGNAL_INTENT_TRANSACTION_FAILED);
        }
      }
    };

    executeSignalIntent();
  }, [
    shouldConfigureSignalIntentWrite, 
    writeSignalIntentAsync, 
    signSignalIntentTransactionStatus
  ]);

  useEffect(() => {
    // If the user hasn't fetched a quote yet or if the usdcAmount is zero, skip
    if (!currentQuote.usdcAmount || currentQuote.usdcAmount === ZERO) return;

    if (token === Token.USDC) {
      updateQueryParams({
        AMOUNT_USDC: null,
        RECIPIENT_ADDRESS: null,
        TO_TOKEN: null,
        TOKEN_AMOUNT: null
      });
    } else {
      updateQueryParams({
        AMOUNT_USDC: currentQuote.usdcAmount.toString(),
        RECIPIENT_ADDRESS: recipientAddress,
        TO_TOKEN: token,
        TOKEN_AMOUNT: tokenUnitsToReadable(
          currentQuote.outputTokenAmount, 
          currentQuote.outputTokenDecimals, 
          tokenInfo[token].decimalsToDisplay
        )
      });
    }
  }, [
    token,
    currentQuote.usdcAmount,
    currentQuote.outputTokenAmount,
    currentQuote.outputTokenDecimals,
    recipientAddress,
  ]);

  useEffect(() => {
    if (token === Token.USDC) {
      const usdcAmount = Number(tokenUnitsToReadable(currentQuote.usdcAmount, 6, 4)).toString();
      setFormattedTokenAmount(usdcAmount !== '0' ? usdcAmount : '0.00');
      setFormattedTokenAmountInUsd(usdcAmount !== '0' ? `$${usdcAmount}` : '');
    } else {
      const decimalsToDisplay = tokenInfo[token].decimalsToDisplay;
      const formattedTokenAmount = currentQuote.outputTokenAmount.toString() !== '0' 
        ? Number(tokenUnitsToReadable(currentQuote.outputTokenAmount, currentQuote.outputTokenDecimals, decimalsToDisplay)) 
        : '0.00';
      setFormattedTokenAmount(formattedTokenAmount.toString());
      setFormattedTokenAmountInUsd(currentQuote.outputTokenAmountInUsd ? `$${currentQuote.outputTokenAmountInUsd}` : '');
    }
  }, [
    token, 
    currentQuote.usdcAmount,
    currentQuote.outputTokenAmount, 
    currentQuote.outputTokenDecimals,
    currentQuote.outputTokenAmountInUsd
  ]);


  /*
   * Event Handlers
   */

  // Reset isHandlingInitialUsdc when user manually edits fiat amount
  const handleFiatInputChange = (value: string) => {
    if (value === "") {
      setFiatAmount('');
      setCurrentQuote(ZERO_QUOTE);
    } else if (value === ".") {
      setFiatAmount('0.');
      setCurrentQuote(ZERO_QUOTE);
    } else if (isValidFiatInput(value)) {
      setFiatAmount(value);
      setCurrentQuote({
        ...currentQuote, 
        fiatAmount: tokenUnits(value, 6) // USDC Decimals
      });
    }
  };

  const handleRecipientAddressChange = (value: string) => {
    setRecipientAddress(value);
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAdd(event as any);
    }
  };

  const handleAdd = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCurrentQuote(ZERO_QUOTE);
  };

  const handleSubmitIntent = async () => {
    setQuoteState(QuoteState.FETCHING_SIGNED_INTENT);

    
    if (chainId && recipientAddress && loggedInEthereumAddress) {
      // If user is swapping to another token on any chain (native or non-native), use the logged 
      // in ethereum address (embedded or injected) for receiving intermediary USDC
      const usdcRecipientAddress = token !== Token.USDC ? loggedInEthereumAddress : recipientAddress;
      
      await fetchSignedIntent({
        processorName: paymentPlatform,
        depositId: currentQuote.depositId.toString(),
        tokenAmount: currentQuote.usdcAmount.toString(),
        payeeDetails: currentQuote.hashedOnchainId,
        toAddress: usdcRecipientAddress,
        fiatCurrencyCode: currencyInfo[fiatCurrency].currencyCodeHash,
        chainId: chainId
      });
    }
  };

  const handleCancelIntent = () => {
    setQuoteState(QuoteState.DEFAULT);
  };

  const handleReferrerCloseClick = () => {
    clearReferrerQueryParams();
    setIsExactOutput(false);

    if (queryParams.REFERRER_RECIPIENT_ADDRESS) {
      if (isLoggedIn && loggedInEthereumAddress) {
        // If user is receiving SOL/ETH/POLYGON/SONIC and is logged in using embedded wallet,
        // set the recipient address to empty string and show the recipient input field
        if (
          tokenInfo[token].networkChainId === SOLANA_CHAIN_ID ||
          (tokenInfo[token].networkChainId === ETH_CHAIN_ID && loginStatus === LoginStatus.AUTHENTICATED) ||
          (tokenInfo[token].networkChainId === POLYGON_CHAIN_ID && loginStatus === LoginStatus.AUTHENTICATED) ||
          (tokenInfo[token].networkChainId === SONIC_CHAIN_ID && loginStatus === LoginStatus.AUTHENTICATED)
        ) {
          setRecipientAddress('');
          setShowRecipientInput(true);
        } else {
          setRecipientAddress(loggedInEthereumAddress);
          setShowRecipientInput(false);
        }
      }
    }
  };

  /*
   * Helpers
   */

  const updateQuoteErrorState = (error: string) => {
    console.log('updateQuoteErrorState: ', error);
    
    setQuoteState(error);
    setShouldConfigureSignalIntentWrite(false);
  };

  function isValidFiatInput(value: any) {
    const isValid = /^-?\d*(\.\d{0,2})?$/.test(value);
    return !isNaN(value) && parseFloat(value) >= 0 && isValid;
  }

  function isValidUsdcInput(value: any) {
    const isValid = /^\d+$/.test(value);
    return !isNaN(value) && parseFloat(value) >= 0 && isValid;
  }

  function isValidAddress(address: string, chainId: number) {
    if (chainId === SOLANA_CHAIN_ID) {
      // Check if it's a base58 encoded string of exactly 32 bytes (44 characters)
      return /^[1-9A-HJ-NP-Za-km-z]{43,44}$/.test(address);
    } else {
      return /^0x[a-fA-F0-9]{40}$/.test(address);
    }
  }

  function isValidOrEmptyAddress(address: string, chainId: number) {
    if (address === '') {
      return true;
    }

    return isValidAddress(address, chainId);
  }

  const tokenBalanceLabel = useMemo(() => {
    if (isLoggedIn && token === Token.USDC) {
      return `Balance: ${tokenUnitsToReadable(usdcBalance ?? ZERO, 6)}`
    } else {
      return '';
    }
  }, [usdcBalance, isLoggedIn, token]);

  const recipientPlaceholderLabel = useMemo(() => {
    return "Wallet address";
  }, []);

  const getButtonText = () => {
    const platformName = paymentPlatformInfo[paymentPlatform].platformName;
    const minFiatAmount = paymentPlatformInfo[paymentPlatform].minFiatAmount;
    const fiatCurrencySymbol = currencyInfo[fiatCurrency].currencySymbol;

    const showSpinner = [
      QuoteState.FETCHING_QUOTE,
      QuoteState.FETCHING_SIGNED_INTENT,
      QuoteState.SIGNAL_INTENT_TRANSACTION_LOADING,
      QuoteState.SIGNAL_INTENT_TRANSACTION_MINING,
    ].includes(quoteState);

    const text = (() => {
      switch (quoteState) {
        case QuoteState.EXCEEDS_ORDER_COUNT:
          return 'Complete existing order';
        case QuoteState.INVALID_RECIPIENT_ADDRESS:
          return 'Enter a valid recipient address';
        case QuoteState.MAINTENANCE:
          return 'Under maintenance';
        case QuoteState.INVALID_FIAT_CURRENCY:
          return 'Invalid currency';
        case QuoteState.FETCHING_QUOTE:
          return 'Fetching quote';
        case QuoteState.PLATFORM_NOT_SUPPORTED_ON_MOBILE:
          return `${platformName} not supported on mobile`;
        case QuoteState.TOO_MANY_REQUESTS_FAILED_TO_FETCH_QUOTE:
          return 'Too many quote requests - Try again later';
        case QuoteState.FAILED_TO_FETCH_QUOTE:
          return 'No quote found';
        case QuoteState.FETCHING_SIGNED_INTENT:
          return 'Fetching signed intent';
        case QuoteState.FAILED_TO_FETCH_SIGNED_INTENT:
          return 'Failed to fetch signed intent - Try again';
        case QuoteState.AMOUNT_BELOW_TRANSFER_MIN:
          return `Send amount less than minimum ${fiatCurrencySymbol}${minFiatAmount}`;
        case QuoteState.SIGNAL_INTENT_TRANSACTION_FAILED:
          return 'Transaction failed - Try again';
        case QuoteState.SIGNAL_INTENT_TRANSACTION_LOADING:
          return 'Signing transaction';
        case QuoteState.SIGNAL_INTENT_TRANSACTION_MINING:
          return 'Mining transaction';
        case QuoteState.FETCH_QUOTE_SUCCESS:
          return 'Start Order';
        case QuoteState.DEFAULT:
        default:
          return `Input ${fiatCurrency} amount`
      }
    })();

    return (
      <ButtonContentWrapper>
        {showSpinner && <StyledSpinner size={20} />}
        <span>{text}</span>
      </ButtonContentWrapper>
    );
  };

  const ctaDisabled = () => {
    switch (quoteState) {
      case QuoteState.DEFAULT:
      case QuoteState.INVALID_FIAT_CURRENCY:
      case QuoteState.FETCHING_QUOTE:
      case QuoteState.FAILED_TO_FETCH_QUOTE:
      case QuoteState.FETCHING_SIGNED_INTENT:
      case QuoteState.FAILED_TO_FETCH_SIGNED_INTENT:
      case QuoteState.AMOUNT_BELOW_TRANSFER_MIN:
      case QuoteState.INVALID_RECIPIENT_ADDRESS:
      case QuoteState.TOO_MANY_REQUESTS_FAILED_TO_FETCH_QUOTE:
      case QuoteState.SIGNAL_INTENT_TRANSACTION_LOADING:
      case QuoteState.SIGNAL_INTENT_TRANSACTION_MINING:
      case QuoteState.MAINTENANCE:
      case QuoteState.PLATFORM_NOT_SUPPORTED_ON_MOBILE:
        return true;

      case QuoteState.EXCEEDS_ORDER_COUNT:
      case QuoteState.FAILED_TO_FETCH_SIGNED_INTENT:
      case QuoteState.SIGNAL_INTENT_TRANSACTION_FAILED:
      case QuoteState.FETCH_QUOTE_SUCCESS:
        return false;
    }
  };

  const ctaClick = () => {
    if (quoteState === QuoteState.EXCEEDS_ORDER_COUNT) {
      onCompleteOrderClick();
    } else {
      handleSubmitIntent();
    }
  };

  
  /*
   * Component
   */

  return (
    <Wrapper>
      {isMobile && showMobileWarning && (
        <WarningContainer>
          <WarningTextBox
            text={commonStrings.get('MOBILE_WARNING_TEXT')}
            showCloseClick={true} 
            onCloseClick={() => setShowMobileWarning(false)}
          />
        </WarningContainer>
      )}

      {showIntegrationModal && (
        <IntegrationModal
          onBackClick={() => setShowIntegrationModal(false)}
        />
      )}

      <SwapFormContainer>
        {!isMobile ? (
          <TitleContainer>
            <ThemedText.HeadlineMedium>
              Buy
            </ThemedText.HeadlineMedium>
          </TitleContainer>
        ) : (
          <TitleContainer>
            <ThemedText.HeadlineSmall>
              Buy
            </ThemedText.HeadlineSmall>
          </TitleContainer>
        )}
        
        {currentIntentHash && (
          <OnRamperIntentInfo
            onCompleteOrderClick={onCompleteOrderClick}
            onCancelIntent={handleCancelIntent}
          />
        )}

        {queryParams.REFERRER && (
          <ReferrerInfo
            onCloseClick={handleReferrerCloseClick}
          />
        )}

        {queryParams.REFERRER && currentIntentHash && (
          <WarningTextBox
            text={commonStrings.get('REDIRECT_FLOW_EXISTING_ORDER_WARNING')}
            showCloseClick={false}
            size="l"
            fontSize="16px"
          />
        )}

        <MainContentWrapper>
          <SwapInputContainer>
            <InputWithCurrencySelector
              label="You send"
              name={`fiatAmount`}
              value={fiatAmount}
              onChange={(e) => handleFiatInputChange(e.target.value)}
              onKeyDown={handleEnterPress}
              type="number"
              placeholder="0.00"
              hasSelector={true}
              selectorDisabled={true}
              selectedCurrency={fiatCurrency}
              setSelectedCurrency={setFiatCurrency}
              readOnly={isExactOutput}
              isPulsing={isExactOutput && quoteState === QuoteState.FETCHING_QUOTE}
            />

            <LabelWithPlatformSelector
              label="Paying using"
              selectedPlatform={paymentPlatform}
              setSelectedPlatform={setPaymentPlatform}
              allPlatforms={allCurrencyPlatforms}
            />

            <InputWithTokenSelector
              label="You receive"
              name={`tokenAmount`}
              value={formattedTokenAmount}
              onChange={() => {}}
              type="number"
              accessoryLabel={tokenBalanceLabel}
              placeholder="0.00"
              readOnly={true}
              hasSelector={true}
              selectedToken={token}
              setSelectedToken={setToken}
              isPulsing={!isExactOutput && quoteState === QuoteState.FETCHING_QUOTE}
              leftAccessoryLabel={formattedTokenAmountInUsd}
              stopSelection={isExactOutput}
              lockLabel={
                isExactOutput ? 
                "You cannot change the token and amount you are receiving. Clear the request above to change token."
                : ''
              }
            />
          </SwapInputContainer>

          {quoteState === QuoteState.FETCH_QUOTE_SUCCESS && (
            <QuoteDetails
              currency={fiatCurrency}
              token={token}
              fiatAmount={fiatAmount}
              usdcAmount={currentQuote.usdcAmount}
              usdcToFiatRate={currentQuote.usdcToFiatRate}
              usdcToTokenRate={currentQuote.usdcToTokenRate}
              outputTokenAmount={currentQuote.outputTokenAmount}
              outputTokenDecimals={currentQuote.outputTokenDecimals}
              outputTokenAmountInUsd={currentQuote.outputTokenAmountInUsd}
              gasFeesInUsd={currentQuote.gasFeesInUsd}
              appFeeInUsd={currentQuote.appFeeInUsd}
              relayerFeeInUsd={currentQuote.relayerFeeInUsd}
              relayerGasFeesInUsd={currentQuote.relayerGasFeesInUsd}
              relayerServiceFeesInUsd={currentQuote.relayerServiceFeesInUsd}
              timeEstimate={currentQuote.timeEstimate}
            />
          )}

          <AddRecipientButtonContainer>
            <AccessoryButton
              onClick={() => setShowRecipientInput(!showRecipientInput)}
              height={36}
              icon={showRecipientInput ? 'minus' : 'plus'}
              title={showRecipientInput ? 'Add Custom Recipient' : 'Add Custom Recipient'}
              iconPosition='left'
              textAlign='right'
              fullWidth={false}
            />
          </AddRecipientButtonContainer>

          {showRecipientInput && (
            <Input
              label="Recipient Address"
              name="recipientAddress"
              value={recipientAddress}
              placeholder={recipientPlaceholderLabel}
              onChange={(e: any) => handleRecipientAddressChange(e.target.value)}
              valueFontSize="16px"
              readOnly={isExactOutput}
              locked={isExactOutput}
              lockLabel={
                isExactOutput ? 
                "You cannot change the recipient address. Clear the request above to change recipient."
                : ''
              }
            />
          )}
          
          {!isLoggedIn ? (
            <CustomConnectButton fullWidth={true} />
          ) : (
          <CTAButton
            disabled={ctaDisabled()}
            onClick={ctaClick}
          >
            {getButtonText()}
          </CTAButton>
          )}
        </MainContentWrapper>
      </SwapFormContainer>

      {/* {currentIntentHash && (
        <>
          <VerticalDivider />
          <OnRamperIntent
            handleCompleteOrderClick={onCompleteOrderClick}
          />
        </>
      )} */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;

  @media (max-width: 600px) {
    max-width: 98%;
    padding: 0;
    box-sizing: border-box;
  }
`;

const SwapFormContainer = styled(AutoColumn)`
  padding: 1rem;
  gap: 1rem;
  background-color: ${colors.container};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  
  @media (max-width: 600px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  margin: 0rem 0.25rem;
  justify-content: space-between;
  align-items: center;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
  border-radius: 4px;
  justify-content: center;
`;

const SwapInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const CTAButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px !important;
  padding: 1rem;
  font-size: 20px;
  font-weight: 550;
  transition: all 75ms;
`;

const VerticalDivider = styled.div`
  height: 24px;
  border-left: 1px solid ${colors.defaultBorderColor};
  margin: 0 auto;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: 8px;
`;

const AddRecipientButtonContainer = styled.div`
  display: grid;
  justify-content: flex-start;
`;

const WarningContainer = styled.div`
  margin-bottom: 16px;
`;

export default SwapForm;
