import React, { useEffect, useState, ReactNode, useCallback } from 'react';
import { esl } from '@helpers/constants';
import { PaymentPlatformType, PaymentPlatform } from '@helpers/types';

import useGetOwnerIntents from '@hooks/backend/useGetOwnerIntents';
import usePayeeDetails from '@hooks/backend/useGetPayeeDetails';
import useGetOwnerDeposits from '@hooks/backend/useGetOwnerDeposits';
import useAccount from '@hooks/contexts/useAccount';
import { Deposit, DepositStatus, Intent } from '@helpers/types/curator';

import BackendContext from './BackendContext';
import { GetPayeeDetailsResponse } from '@helpers/types';


interface ProvidersProps {
  children: ReactNode;
}

const BackendProvider = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */
  const { loggedInEthereumAddress, isLoggedIn } = useAccount();

  /*
   * Hooks
   */
  const { 
    fetchPayeeDetails: fetchPayeeDetailsImpl, 
    data: payeeDetailsResponse
  } = usePayeeDetails();
  
  const {
    data: ownerDeposits,
    isLoading: isLoadingOwnerDeposits,
    error: ownerDepositsError,
    fetchOwnerDeposits
  } = useGetOwnerDeposits();

  /*
   * State for payee details
   */
  const [rawPayeeDetails, setRawPayeeDetails] = useState<string>('');
  const [depositorTgUsername, setDepositorTgUsername] = useState<string>('');
  const [isFetchingRawPayeeDetails, setIsFetchingRawPayeeDetails] = useState<boolean>(false);

  /*
   * Effects for payee details
   */
  useEffect(() => {
    esl && console.log('rawPayeeDetails_1');

    if (payeeDetailsResponse) {
      esl && console.log('rawPayeeDetails_2');
      esl && console.log('rawPayeeDetails:', payeeDetailsResponse);

      const platform = payeeDetailsResponse.responseObject.processorName;

      const extractRawPayeeDetails = (data: GetPayeeDetailsResponse) => {
        switch(platform) {
          case PaymentPlatform.VENMO:
            return data.responseObject.depositData.venmoUsername;
          case PaymentPlatform.REVOLUT:
            return data.responseObject.depositData.revolutUsername;
          case PaymentPlatform.CASHAPP:
            return data.responseObject.depositData.cashtag;
          case PaymentPlatform.WISE:
            return data.responseObject.depositData.wisetag;
          case PaymentPlatform.MERCADO_PAGO:
            return data.responseObject.depositData.cvu;
          default:
            return '';
        }
      };

      const tgUsername = payeeDetailsResponse.responseObject.depositData.telegramUsername;
      if (tgUsername) {
        setDepositorTgUsername(tgUsername);
      } else {
        setDepositorTgUsername('');
      }

      setRawPayeeDetails(extractRawPayeeDetails(payeeDetailsResponse));
      setIsFetchingRawPayeeDetails(false);
    }
  }, [payeeDetailsResponse]);

  // Payee details handler
  useEffect(() => { 
    if (isLoggedIn && loggedInEthereumAddress) {
      fetchOwnerDeposits(loggedInEthereumAddress);
    }
  }, [isLoggedIn, loggedInEthereumAddress, fetchOwnerDeposits]);

  const fetchPayeeDetails = useCallback((hashedOnchainId: string, platform: PaymentPlatformType) => {
    setIsFetchingRawPayeeDetails(true);
    fetchPayeeDetailsImpl(hashedOnchainId, platform);
  }, [fetchPayeeDetailsImpl]);
  
  const refetchOwnerDeposits = useCallback(async () => {
    if (loggedInEthereumAddress) {
      await fetchOwnerDeposits(loggedInEthereumAddress);
    }
  }, [fetchOwnerDeposits, loggedInEthereumAddress]);


  return (
    <BackendContext.Provider
      value={{
        // Payee details values
        rawPayeeDetails,
        depositorTgUsername,
        fetchPayeeDetails,
        isFetchingRawPayeeDetails,
        
        // Owner deposits values
        ownerDeposits,
        isLoadingOwnerDeposits,
        ownerDepositsError,
        fetchOwnerDeposits,
        refetchOwnerDeposits
      }}
    >
      {children}
    </BackendContext.Provider>
  );
};

export default BackendProvider;