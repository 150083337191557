import { ethers, BigNumber } from "ethers";


export const etherUnits = (amount: number | string | BigNumber): BigNumber => {
  return ethers.utils.parseEther(amount.toString());
};

export const usdcUnits = (amount: number | string | BigNumber): BigNumber => {
  return ethers.utils.parseUnits(amount.toString(), 6);
};

export const tokenUnits = (amount: number | string | BigNumber, decimals: number): BigNumber => {
  return ethers.utils.parseUnits(amount.toString(), decimals);
};

export const etherUnitsToReadable = (amount: BigNumber | string, decimalsToDisplay: number = 2): string => {
  return Number(Number(ethers.utils.formatEther(amount)).toFixed(decimalsToDisplay)).toString();
};

export const tokenUnitsToReadable = (amount: BigNumber | string, decimals: number, decimalsToDisplay: number = 2): string => {
  const formatted = Number(ethers.utils.formatUnits(amount, decimals));
  const multiplier = Math.pow(10, decimalsToDisplay);
  return (Math.floor(formatted * multiplier) / multiplier).toFixed(decimalsToDisplay);
};
