import { useState, useCallback } from "react";
import debounce from "lodash/debounce";
import { QuoteMaxTokenForFiatRequest, QuoteResponse } from "@helpers/types/curator";

const API_URL = process.env.CURATOR_API_URL || "";

const QUOTE_FETCHING_DEBOUNCE_MS = 500;

export default function useQuoteMaxTokenForExactFiat() {
  /**
   * State
   */
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<QuoteResponse | null>(null);

  /**
   * Fetch
   */
  const fetchQuoteImpl = useCallback(async (requestData: QuoteMaxTokenForFiatRequest) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/v1/quote/exact-fiat`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to fetch quoteMaxTokenForExactFiat:", errorText);
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const jsonResponse = await response.json() as QuoteResponse;

      setData(jsonResponse);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchQuote = useCallback(
    debounce((requestData: QuoteMaxTokenForFiatRequest) => {
      fetchQuoteImpl(requestData);
    }, QUOTE_FETCHING_DEBOUNCE_MS),
    [fetchQuoteImpl]
  );

  return {
    data,
    isLoading,
    error,
    fetchQuote
  };
}
