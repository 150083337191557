import { useEffect, useState, ReactNode } from 'react'
import { erc20ABI } from 'wagmi';

import { abi as escrowAbi } from "@helpers/abi/escrow.abi";
import { abi as venmoReclaimVerifierAbi } from "@helpers/abi/venmoReclaimVerifier.abi";
import { abi as revolutReclaimVerifierAbi } from "@helpers/abi/revolutReclaimVerifier.abi";
import { abi as cashappReclaimVerifierAbi } from "@helpers/abi/cashappReclaimVerifier.abi";
import { abi as wiseReclaimVerifierAbi } from "@helpers/abi/wiseReclaimVerifier.abi";
import { abi as mercadopagoReclaimVerifierAbi } from "@helpers/abi/mercadoPagoReclaimVerifier.abi";

import { contractAddresses, blockExplorerUrls, chainIds } from "@helpers/deployed_addresses";
import { esl, DEFAULT_NETWORK } from '@helpers/constants';
import { Abi, PaymentPlatform, PaymentPlatformType } from '@helpers/types';
import useAccount from '@hooks/contexts/useAccount'

import SmartContractsContext from './SmartContractsContext';


interface ProvidersProps {
  children: ReactNode;
}

const SmartContractsProvider = ({ children }: ProvidersProps) => {
  /*
   * Context
   */

  const { network, isLoggedIn } = useAccount();

  /*
   * State
   */

  const [usdcAddress, setUsdcAddress] = useState<string | null>(null);
  const [usdtAddress, setUsdtAddress] = useState<string | null>(null);
  const [blockscanUrl, setBlockscanUrl] = useState<string>(blockExplorerUrls[DEFAULT_NETWORK]);
  const [solanaBlockscanUrl, setSolanaBlockscanUrl] = useState<string | null>(null);
  const [ethBlockscanUrl, setEthBlockscanUrl] = useState<string | null>(null);
  const [polygonBlockscanUrl, setPolygonBlockscanUrl] = useState<string | null>(null);
  const [chainId, setChainId] = useState<string | null>(chainIds[DEFAULT_NETWORK]);

  // Escrow
  const [escrowAddress, setEscrowAddress] = useState<string | null>(null);

  // Verifiers
  const [platformToVerifierAddress, setPlatformToVerifierAddress] = useState<{ [key in PaymentPlatformType]?: string }>({});
  const [platformToVerifierAbi, setPlatformToVerifierAbi] = useState<{ [key in PaymentPlatformType]?: Abi }>({});
  const [addressToPlatform, setAddressToPlatform] = useState<{ [key: string]: PaymentPlatformType }>({});

  // Gating Service
  const [gatingServiceAddress, setGatingServiceAddress] = useState<string | null>(null);

  // Witness Signer Addresses
  const [witnessAddresses, setWitnessAddresses] = useState<string[] | null>(null);

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('smartContracts_1');
    esl && console.log('checking network: ', network);
    esl && console.log('isLoggedIn: ', isLoggedIn);

    const deploymentEnvironment = process.env.DEPLOYMENT_ENVIRONMENT || 'LOCAL';

    let networkToUse = null;
    if (isLoggedIn) {
      networkToUse = network;
    } else {
      switch (deploymentEnvironment) {
        case 'PRODUCTION':
        case 'STAGING':
          networkToUse = 'base';
          break;

        case 'STAGING_TESTNET':
          networkToUse = 'sepolia';
          break;

        default:
          networkToUse = 'hardhat';
          break;
      }
    }

    if (networkToUse) {
      switch (deploymentEnvironment) {
        case 'PRODUCTION':
          setAddressWithNetworkEnvKey(networkToUse, 'base_production');
          break;
  
        default:
          switch (networkToUse) {
            case 'base':
              setAddressWithNetworkEnvKey(networkToUse, 'base_staging');
              break;
  
            case 'sepolia':
              setAddressWithNetworkEnvKey(networkToUse, 'sepolia_staging');
              break;
  
            case 'hardhat':
            default:
              setAddressWithNetworkEnvKey(networkToUse, 'localhardhat');
              break;
          }
        }
    } else {
      setEmptyAddresses();
    }
  }, [network, isLoggedIn]);

  /*
   * Helpers
   */

  const setEmptyAddresses = () => {
    setBlockscanUrl(blockExplorerUrls['base']);
    setEthBlockscanUrl(blockExplorerUrls['eth']);
    setSolanaBlockscanUrl(blockExplorerUrls['solana']);
    setPolygonBlockscanUrl(blockExplorerUrls['polygon']);
    setChainId(chainIds['base']);

    setUsdcAddress(null);
    setUsdtAddress(null);
    // Escrow
    setEscrowAddress(null);

    // Gating Service
    setGatingServiceAddress(null);

    // Witness Signer Address
    setWitnessAddresses(null);

    // Verifiers
    setPlatformToVerifierAddress({});
    setPlatformToVerifierAbi({});
    setAddressToPlatform({}); 
  };

  const setAddressWithNetworkEnvKey = (network: string, networkEnvKey: string) => {
    const contractsForNetwork = contractAddresses[networkEnvKey];

    setBlockscanUrl(blockExplorerUrls[network]);
    setEthBlockscanUrl(blockExplorerUrls['eth']);
    setSolanaBlockscanUrl(blockExplorerUrls['solana']);
    setPolygonBlockscanUrl(blockExplorerUrls['polygon']);
    setChainId(chainIds[network]);
    setUsdcAddress(contractsForNetwork.usdc);
    setUsdtAddress(contractsForNetwork.usdt);
    // Escrow
    setEscrowAddress(contractsForNetwork.escrow);

    // Gating Service
    setGatingServiceAddress(contractsForNetwork.gatingService);

    // Witness Signer Address
    setWitnessAddresses([
      contractsForNetwork.zkp2pWitnessSigner,
      contractsForNetwork.reclaimWitnessSigner,
    ]);

    // Verifiers
    setPlatformToVerifierAddress({
      [PaymentPlatform.VENMO]: contractsForNetwork.venmoReclaimVerifier,
      [PaymentPlatform.REVOLUT]: contractsForNetwork.revolutReclaimVerifier,
      [PaymentPlatform.CASHAPP]: contractsForNetwork.cashappReclaimVerifier,
      [PaymentPlatform.WISE]: contractsForNetwork.wiseReclaimVerifier,
      [PaymentPlatform.MERCADO_PAGO]: contractsForNetwork.mercadopagoReclaimVerifier,
    });
    setPlatformToVerifierAbi({
      [PaymentPlatform.VENMO]: venmoReclaimVerifierAbi as Abi,
      [PaymentPlatform.REVOLUT]: revolutReclaimVerifierAbi as Abi,
      [PaymentPlatform.CASHAPP]: cashappReclaimVerifierAbi as Abi,
      [PaymentPlatform.WISE]: wiseReclaimVerifierAbi as Abi,
      [PaymentPlatform.MERCADO_PAGO]: mercadopagoReclaimVerifierAbi as Abi,
    });
    setAddressToPlatform({
      [contractsForNetwork.venmoReclaimVerifier]: PaymentPlatform.VENMO,
      [contractsForNetwork.revolutReclaimVerifier]: PaymentPlatform.REVOLUT,
      [contractsForNetwork.cashappReclaimVerifier]: PaymentPlatform.CASHAPP,
      [contractsForNetwork.wiseReclaimVerifier]: PaymentPlatform.WISE,
      [contractsForNetwork.mercadopagoReclaimVerifier]: PaymentPlatform.MERCADO_PAGO,
    }); 
  };

  return (
    <SmartContractsContext.Provider
      value={{
        usdcAddress,
        usdcAbi: erc20ABI as any,
        usdtAddress,
        usdtAbi: erc20ABI as any,
        blockscanUrl: blockscanUrl,
        solanaBlockscanUrl: solanaBlockscanUrl,
        ethBlockscanUrl: ethBlockscanUrl,
        polygonBlockscanUrl: polygonBlockscanUrl,
        chainId,
        // Escrow
        escrowAddress,
        escrowAbi: escrowAbi as Abi,  

        // Verifier for each platform
        platformToVerifierAddress,
        platformToVerifierAbi,
        addressToPlatform,

        // Gating Service
        gatingServiceAddress,

        // Witness Signer Address
        witnessAddresses,
      }}
    >
      {children}
    </SmartContractsContext.Provider>
  );
};

export default SmartContractsProvider;
