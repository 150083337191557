import React, { useEffect, useState } from 'react';
import { Link as LinkIcon, Filter, ArrowUp, ArrowDown } from 'react-feather';
import Link from '@mui/material/Link';
import styled, { css } from 'styled-components/macro';
import { colors } from '@theme/colors';
import { ThemedText } from '@theme/text';
import { Button } from '@components/common/Button';
import { useNavigate } from 'react-router-dom';

import { currencies, getCurrencyInfoFromHash, Token, tokenInfo, TokenType } from '@helpers/types';
import { CurrencyType, currencyInfo } from '@helpers/types';
import { PaymentPlatformType,  paymentPlatformInfo, paymentPlatforms } from '@helpers/types';
import { tokenUnitsToReadable, etherUnitsToReadable, usdcUnits } from '@helpers/units';
import { Currency, isSupportedCurrency } from '@helpers/types/currency';
import { AccessoryButton } from '@components/common/AccessoryButton';
import SortableColumnHeader from '@components/common/SortableColumnHeader';
import { CurrencySelector } from '@components/modals/selectors/currency';
import { PlatformSelector } from '@components/modals/selectors/platform';
import { ZERO_ADDRESS } from '@helpers/constants';
import { APR_DOCS_LINK } from '@helpers/docUrls';
import useGeolocation from '@hooks/contexts/useGeolocation';
import useMediaQuery from '@hooks/useMediaQuery';
import useLiquidity from '@hooks/contexts/useLiquidity';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useCurrencyPrices from '@hooks/useCurrencyPrices';
import { useDuneVolume } from '@hooks/useDuneVolume';

import { LiquidityRow } from './LiquidityRow';
import { LiquidityRowMobile } from './LiquidityRowMobile';
import { HorizontalInput } from '@components/common/HorizontalInput';

import { esl } from '@helpers/constants';
import useQuery from '@hooks/useQuery';
import { calculateAPR } from '@helpers/aprHelper';
import { TokenSelector } from '@components/modals/selectors/token/TokenSelector';


const ROWS_PER_PAGE = 11;

interface LiquidityRowData {
  depositId: string;
  depositor: string;
  token: TokenType;
  availableLiquidity: string;
  currency: CurrencyType;
  conversionRate: string;
  platform: PaymentPlatformType;
  intentAmountRange: {
    min: string;
    max: string;
  };
  apr: number | null;
  spread: number | null;
  hashedOnchainId: string;
}

export const LiquidityTable: React.FC = () => {

  LiquidityTable.displayName = 'LiquidityTable';
  /*
   * Contexts
   */

  const navigate = useNavigate();
  const currentDeviceSize = useMediaQuery();
  const isMobile = currentDeviceSize === 'mobile';
  
  const {addressToPlatform, usdcAddress} = useSmartContracts();
  const { depositViews } = useLiquidity();
  const { currencyCode } = useGeolocation();
  const { queryParams } = useQuery();
  const { 
    platformVolumes, 
    platformLiquidities, 
    isLoading: volumeLoading, 
    error: volumeError 
  } = useDuneVolume();

  /*
   * State
   */

  const [liquidityRows, setLiquidityRows] = useState<LiquidityRowData[]>([]);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<string | null>('price');
  
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyType | null>(
    queryParams.REFERRER_FROM_CURRENCY ? (
      isSupportedCurrency(queryParams.REFERRER_FROM_CURRENCY) 
      ? queryParams.REFERRER_FROM_CURRENCY 
      : 'USD'
    ) : null
  );
  const [selectedPlatform, setSelectedPlatform] = useState<PaymentPlatformType | null>(null);
  const [allPlatforms, setAllPlatforms] = useState<PaymentPlatformType[]>(paymentPlatforms);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedData, setPaginatedData] = useState<LiquidityRowData[]>([]);
  
  const { prices: currencyPrices } = useCurrencyPrices(currencies, Currency.USD);

  const [usdcAmount, setUsdcAmount] = useState<string>('');

  const [selectedToken, setSelectedToken] = useState<TokenType | null>(Token.USDC);

  const hasActiveFilters = selectedCurrency !== null || selectedPlatform !== null || !!usdcAmount || selectedToken !== Token.USDC;

  /*
   * Hooks
   */

  useEffect(() => {
    const storedCurrency = window.sessionStorage.getItem('lastUsedFiatCurrency');
    if (!storedCurrency) {
      const currencyFromQuery = queryParams.REFERRER_FROM_CURRENCY;
      if (currencyFromQuery && isSupportedCurrency(currencyFromQuery)) {
        setSelectedCurrency(currencyFromQuery);
      } else if (currencyCode && isSupportedCurrency(currencyCode)) {
        setSelectedCurrency(currencyCode);
      } else {
        setSelectedCurrency('USD');
      }
    } else {
      setSelectedCurrency(storedCurrency.replace(/['"]+/g, '') as CurrencyType);
    }
  }, [currencyCode, queryParams.REFERRER_FROM_CURRENCY]);

  useEffect(() => {
    setCurrentPage(0);

    if (!depositViews) return;

    let filteredViews = depositViews.filter(
      depositView => (
        depositView.deposit.depositor !== ZERO_ADDRESS &&
        depositView.availableLiquidity.gt(usdcUnits(0.1)) &&
        depositView.deposit.acceptingIntents
      )
    );

    let liquidityRowsData: LiquidityRowData[] = [];

    filteredViews.forEach(depositView => {
      const depositId = depositView.depositId.toString();
      const depositor = depositView.deposit.depositor;
      const depositAmount = depositView.deposit.depositAmount;
      const tokenAddress = depositView.deposit.token;
      const availableLiquidity = depositView.availableLiquidity;
      const token = Token.USDC;
      const tokenDecimals = tokenInfo[token].tokenDecimals;
      const intentAmountRange = {
        min: tokenUnitsToReadable(depositView.deposit.intentAmountRange.min, tokenDecimals, 1),
        max: tokenUnitsToReadable(depositView.deposit.intentAmountRange.max, tokenDecimals, 1)
      };

      depositView.verifiers.forEach(verifier => {
        const verifierAddress = verifier.verifier;
        const platform = addressToPlatform[verifierAddress];

        verifier.currencies.forEach(currency => {
          const currencyCode = currency.code;
          const currencyInfo = getCurrencyInfoFromHash(currencyCode);
          const conversionRate = currency.conversionRate;

          if (!currencyInfo) return;

          // Filter by USDC amount if entered
          if (usdcAmount) {
            const usdcValue = usdcUnits(usdcAmount);
            const withinLimits = usdcValue.gte(depositView.deposit.intentAmountRange.min) && 
                                usdcValue.lte(depositView.deposit.intentAmountRange.max);
            const hasEnoughLiquidity = usdcValue.lte(depositView.availableLiquidity);
            
            if (!withinLimits || !hasEnoughLiquidity) return;
          }

          const currencyPrice = currencyPrices[currencyInfo.currencyCode];
          const { apr, spread } = currencyPrice ? calculateAPR(
            availableLiquidity.toBigInt(), 
            conversionRate.toBigInt(), 
            currencyPrice,
            platformVolumes[platform as keyof typeof platformVolumes],
            platformLiquidities[platform as keyof typeof platformLiquidities]
          ) : { apr: null, spread: null };

          liquidityRowsData.push({
            depositId,
            depositor,
            token,
            availableLiquidity: tokenUnitsToReadable(availableLiquidity, tokenDecimals),
            currency: currencyInfo.currencyCode,
            conversionRate: Number(etherUnitsToReadable(conversionRate, 4)).toString(),
            platform,
            intentAmountRange,
            apr: apr,
            spread: spread,
            hashedOnchainId: verifier.verificationData.payeeDetails
          });
        });
      });
    });

    esl && console.log('liquidityRowsData', liquidityRowsData);

    // Apply sorting
    if (sortColumn === 'price') {
      liquidityRowsData.sort((a, b) => {
        const priceA = parseFloat(a.conversionRate);
        const priceB = parseFloat(b.conversionRate);
        return sortDirection === 'asc' ? priceA - priceB : priceB - priceA;
      });
    } else if (sortColumn === 'available') {
      liquidityRowsData.sort((a, b) => {
        const availableA = parseFloat(a.availableLiquidity);
        const availableB = parseFloat(b.availableLiquidity);
        return sortDirection === 'asc' ? availableA - availableB : availableB - availableA;
      });
    } else if (sortColumn === 'limits') {
      liquidityRowsData.sort((a, b) => {
        const maxLimitA = parseFloat(a.intentAmountRange.max);
        const maxLimitB = parseFloat(b.intentAmountRange.max);
        return sortDirection === 'asc' ? maxLimitA - maxLimitB : maxLimitB - maxLimitA;
      });
    } else if (sortColumn === 'apr') {
      liquidityRowsData.sort((a, b) => {
        const aprA = a.apr === null ? -1 : a.apr;
        const aprB = b.apr === null ? -1 : b.apr;
        return sortDirection === 'asc' ? aprA - aprB : aprB - aprA;
      });
    }

    // Apply currency filter
    if (selectedCurrency) {
      liquidityRowsData = liquidityRowsData.filter(row =>
        row.currency === selectedCurrency
      );
    }

    // Apply platform filter 
    if (selectedPlatform) {
      liquidityRowsData = liquidityRowsData.filter(row =>
        row.platform === selectedPlatform
      );
    }

    setLiquidityRows(liquidityRowsData);
  }, [
    depositViews, 
    selectedCurrency, 
    selectedPlatform, 
    sortColumn, 
    sortDirection, 
    platformVolumes, 
    platformLiquidities,
    usdcAmount,
    selectedToken
  ]);


  useEffect(() => {
    if (liquidityRows) {
      setTotalPages(Math.ceil(liquidityRows.length / ROWS_PER_PAGE));
      setPaginatedData(liquidityRows.slice(currentPage * ROWS_PER_PAGE, (currentPage + 1) * ROWS_PER_PAGE));
    }
  }, [liquidityRows, currentPage]);


  useEffect(() => {
    if (selectedCurrency) {
      const platformsThatSupportCurrency = Object.entries(paymentPlatformInfo).filter(
        ([_, platformData]) => platformData.platformCurrencies.includes(selectedCurrency)
      );
      
      setAllPlatforms(platformsThatSupportCurrency.map(([platform]) => platform));
    }
  }, [selectedCurrency]);
  
  /*
   * Handlers
   */

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleClearFilters = () => {
    setSelectedCurrency(null);
    setSelectedPlatform(null);
    setAllPlatforms(paymentPlatforms);
    setUsdcAmount('');
    setSelectedToken(Token.USDC);
  };

  const handleAddLiquidity = () => {
    navigate('/pool');
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      // Toggle sort direction if clicking the same column
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new column and default to ascending
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  /*
   * Component
   */

  const renderPageUpdateButtons = () => {

    if (liquidityRows.length === 0 || totalPages === 0 || liquidityRows.length <= ROWS_PER_PAGE) {
      return null;
    }

    return (
      <PaginationContainer>
        <PaginationButton disabled={currentPage === 0} onClick={() => handleChangePage(currentPage - 1)}>
          &#8249;
        </PaginationButton>
        <PageInfo>
          {totalPages === 0 ? '0 of 0' : `${currentPage + 1} of ${totalPages}`}
        </PageInfo>
        <PaginationButton
          disabled={currentPage === totalPages - 1 || totalPages === 0}
          onClick={() => handleChangePage(currentPage + 1)}>  
          &#8250;
        </PaginationButton>
      </PaginationContainer>
    );
  }

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineMedium>
          Liquidity
        </ThemedText.HeadlineMedium>
      </TitleRow>

      <FiltersAndAddLiquidityContainer>
        <FiltersContainer>
          <HorizontalInput
            type="number"
            label="Buy"
            name="usdcAmount"
            value={usdcAmount}
            placeholder="Amount"
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
                setUsdcAmount(value);
              }
            }}
            inputLabel={selectedToken || 'USDC'}
            width="180px"
          />

          {!isMobile && (
            <TokenSelector
              selectedToken={selectedToken || Token.USDC}
              setSelectedToken={setSelectedToken}
              onlyShowDepositAllowedTokens={true}
            />
          )}
          
          <CurrencyAndPlatformSelectorContainer>  
            <CurrencySelector
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              allCurrencies={currencies}
            />
          
            <PlatformSelector
              paymentPlatform={selectedPlatform}
              setPaymentPlatform={setSelectedPlatform}
              allPlatforms={allPlatforms}
            />

            <FilterButton 
                onClick={handleClearFilters} 
                active={hasActiveFilters || selectedToken !== Token.USDC}
                disabled={!hasActiveFilters && selectedToken === Token.USDC}
              >
            <Filter size={20} />
            </FilterButton>
          </CurrencyAndPlatformSelectorContainer>
        </FiltersContainer>

        {!isMobile && (
          <AddLiquidityButtonContainer>
            <AccessoryButton
              onClick={handleAddLiquidity}
              height={34}
              icon="plus"
              iconPosition="left"
              title="Add Liquidity"
            />
          </AddLiquidityButtonContainer>
        )}
        
      </FiltersAndAddLiquidityContainer>

      <Content>
        {liquidityRows?.length === 0 || liquidityRows == null ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <ChainLinkIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                No deposits found.
              </div>
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        ) : (
          <TableContainer>
            {!isMobile ? (
              <>
                <TableHeaderRow>
                  <SortableColumnHeader
                    column="price"
                    currentSortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSort={handleSort}
                    tooltip="Ask per USDC. Positive spread means the price is higher than the current market price of USD and vice versa. Spread updates every 3 hours."
                  >
                    Price <SpreadBadge>Spread</SpreadBadge>
                  </SortableColumnHeader>
                  <SortableColumnHeader
                    column="apr"
                    currentSortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSort={handleSort}
                    tooltip={
                      <>
                        Annual Percentage Rate and spread based on protocol volume, liquidity share, and conversion rate. Updates every 3 hours.
                        {' '}
                        <Link
                          href={APR_DOCS_LINK}
                          target='_blank'
                          style={{ color: 'inherit' }}
                        >
                          Read more ↗
                        </Link>
                      </>
                    }
                  >
                    <APRHeaderContainer>
                      APR
                    </APRHeaderContainer>
                  </SortableColumnHeader>
                  <ColumnHeader>Depositor</ColumnHeader>
                  <ColumnHeader>Payment</ColumnHeader>
                  <SortableColumnHeader
                    column="available"
                    currentSortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSort={handleSort}
                    tooltip="Amount available to be taken. Click to sort by available liquidity" 
                  >
                    Available
                  </SortableColumnHeader>
                  <SortableColumnHeader
                    column="limits"
                    currentSortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSort={handleSort}
                    tooltip="Minimum and maximum USDC amount that can be taken per order. Click to sort by limits" 
                  >
                    <span>Limits</span>
                  </SortableColumnHeader>
                  <TradeColumnHeader>Trade</TradeColumnHeader>
                </TableHeaderRow>
                <Table>
                  {paginatedData.map((liquidityRow, rowIndex) => (
                    <PositionRowStyled key={rowIndex}>
                      <LiquidityRow
                        depositId={liquidityRow.depositId}
                        depositor={liquidityRow.depositor}
                        token={liquidityRow.token}
                        availableLiquidity={liquidityRow.availableLiquidity}
                        currency={liquidityRow.currency}
                        conversionRate={liquidityRow.conversionRate}
                        platform={liquidityRow.platform}
                        rowIndex={rowIndex + currentPage * ROWS_PER_PAGE}
                        limits={liquidityRow.intentAmountRange}
                        apr={liquidityRow.apr}
                        spread={liquidityRow.spread}
                        hashedOnchainId={liquidityRow.hashedOnchainId}
                      />
                    </PositionRowStyled>
                  ))}
                </Table>
              </>
            ) : (
               <>
                <TableMobile>
                  {paginatedData.map((liquidityRow, rowIndex) => (
                    <LiquidityRowMobile
                      key={rowIndex}
                      depositor={liquidityRow.depositor}
                      token={liquidityRow.token}
                      availableLiquidity={liquidityRow.availableLiquidity}
                      currency={liquidityRow.currency}
                      conversionRate={liquidityRow.conversionRate}
                      platform={liquidityRow.platform}
                      limits={liquidityRow.intentAmountRange}
                      apr={liquidityRow.apr}
                      spread={liquidityRow.spread}
                    />
                  ))}
                </TableMobile>
              </>
            )}
          </TableContainer>
        )}
      </Content>

      {renderPageUpdateButtons()}
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 600px) {
    margin: 0 auto;
    width: 98%;
  }
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  height: 50px;
  align-items: flex-end;
  justify-content: space-between;
  color: #FFF;
  padding: 0 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 0;
  }
`;

const Content = styled.main`
  @media (min-width: 600px) {  
    background-color: ${colors.container};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 24px 32px rgba(0, 0, 0, 0.01);  
    border: 1px solid ${colors.defaultBorderColor};
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;

  @media (max-width: 600px) {
    background-color: ${colors.container};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 24px 32px rgba(0, 0, 0, 0.01);  
    border: 1px solid ${colors.defaultBorderColor};
    border-radius: 16px;
  }
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const ChainLinkIcon = styled(LinkIcon)`
  ${IconStyle}
`;

const TableContainer = styled.div`
  display: block;
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1.2fr 1fr;
  padding: 1.3rem 1.5rem 1rem 1.5rem;
  gap: 1.5rem;
  text-align: left;
  border-bottom: 1px solid ${colors.defaultBorderColor};

  // @media (min-width: 600px) {
  //   // grid-template-columns: .2fr .9fr .6fr 1.1fr repeat(2, minmax(0,1fr));
  //   // padding: 1.3rem 1.75rem 1rem 1.75rem;
  // }
`;

const Table = styled.div`
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  color: #616161;

  @media (max-width: 600px) {
    font-size: 12px;
  };

  & > * {
    border-bottom: 1px solid ${colors.defaultBorderColor};
  }

  & > *:last-child {
    border-bottom: none;
  }
`;

const TableMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  opacity: 0.7;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const TradeColumnHeader = styled.div`
  text-align: right;
  font-size: 14px;
  opacity: 0.7;
  margin-right: 0.5rem;

  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const ColumnHeaderWithHelper = styled.div`
  text-align: left;
  font-size: 14px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 6px;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const PaginationButton = styled.button`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px 16px;
  margin: 0 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  color: rgba(255, 255, 255, 0.8);
  word-spacing: 2px;
  font-size: 16px;
`;

const PositionRowStyled = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const CurrencyAndPlatformSelectorContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 600px) {
    gap: 0.3rem;
  }
`;

const FiltersAndAddLiquidityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9rem;
  justify-content: space-between;
  padding: 0.5rem 1rem 1rem 1rem;

  @media (max-width: 600px) {
    gap: 0.3rem;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
`;

const FilterButton = styled.button<{ active: boolean }>`
  background: transparent;
  border: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s ease;
  position: relative;

  // Default/Idle state
  color: ${colors.lightGrayText};

  // Hover state
  &:hover:not(:disabled) {
    color: ${colors.buttonHover};
    cursor: pointer;
  }

  // Active/Filters Applied state
  ${props => props.active && css`
    color: ${colors.buttonDefault};
    
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      width: 8px;
      height: 8px;
      background-color: ${colors.buttonDefault};
      border-radius: 50%;
    }

    &:hover {
      color: ${colors.buttonHover};
      
      &::after {
        background-color: ${colors.buttonHover};
      }
    }
  `}

  // Disabled state
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const AddLiquidityButtonContainer = styled.div`
  margin-right: 0.5rem;

  @media (max-width: 600px) {
    margin-right: 0.5rem;
    font-size: 14px;
    padding: 8px 12px;
  }
`;

const APRHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SpreadBadge = styled.span`
  background-color: rgba(108, 117, 125, 0.2);
  color: #9AA3AF;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
`;
