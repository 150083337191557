import styled from 'styled-components/macro';
import { colors } from '@theme/colors';

import { AutoColumn } from '@components/layouts/Column';
import { LiquidityTable } from '@components/Liquidity/LiquidityTable';
import useMediaQuery from '@hooks/useMediaQuery';

export default function Liquidity() {
  /*
   * Component
   */

  return (
    <Wrapper>
      <LiquidityTable/>
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  max-width: 1120px;
  width: 100%;
`;