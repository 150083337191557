import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ArrowLeft, AlertTriangle } from 'react-feather';
import QRCode from 'react-qr-code';
import Link from '@mui/material/Link';

import { Button } from '@components/common/Button';
import { Details } from '@components/Swap/SendPayment/Details';
import { PaymentPlatformType } from '@helpers/types';
import { commonStrings } from '@helpers/strings';
import { Breadcrumb, BreadcrumbStep } from '@components/common/Breadcrumb';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { CurrencyType, PaymentPlatform, paymentPlatformInfo, currencyInfo } from '@helpers/types';
import { parseIntentData, ParsedIntentData } from '@helpers/intentHelper';
import { RowBetween } from '@components/layouts/Row';
import { WarningTextBox } from '@components/common/WarningTextBox';

import useBackend from '@hooks/contexts/useBackend';
import useOnRamperIntents from '@hooks/contexts/useOnRamperIntents';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useMediaQuery from '@hooks/useMediaQuery';
import { AccessoryButton } from '@components/common/AccessoryButton';
import useExtensionProxyProofs from '@hooks/contexts/useExtensionProxyProofs';
import { PaymentPlatformDefaultPaymentMode } from '@helpers/types/paymentPlatform';
import { isVersionOutdated } from '@helpers/sidebar';

interface ViewConfig {
  showQRCodePayment: boolean;
  showSendPaymentOnDesktopOption: boolean;
  expandDetails: boolean;
  showSendPaymentButton: boolean;
  isConfirmationButtonAccessory: boolean;
}

interface SendPaymentFormProps {
  onBackClick: () => void
  onCompleteClick: () => void
}

export const SendPaymentForm: React.FC<SendPaymentFormProps> = ({
  onBackClick,
  onCompleteClick,
}) => {

  /*
   * Context
   */

  const currentDeviceSize = useMediaQuery();
  const isMobile = currentDeviceSize === 'mobile';

  const { rawPayeeDetails, fetchPayeeDetails, isFetchingRawPayeeDetails } = useBackend();
  const { currentIntentView } = useOnRamperIntents();
  const { addressToPlatform } = useSmartContracts();
  const { isSidebarInstalled, sideBarVersion } = useExtensionProxyProofs();

  /*
   * State
   */

  const [intentData, setIntentData] = useState<ParsedIntentData | null>(null);
  const [viewConfig, setViewConfig] = useState<ViewConfig>({
    showQRCodePayment: false,
    showSendPaymentOnDesktopOption: false,
    expandDetails: false,
    showSendPaymentButton: false,
    isConfirmationButtonAccessory: false,
  });
  const [link, setLink] = useState<string | null>(null);
  const [troubleScanningQRCodeLink, setTroubleScanningQRCodeLink] = useState<string | null>(null);
  const [imageError, setImageError] = useState(false);
  const [paymentWarning, setPaymentWarning] = useState<string | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<string | null>(null);
  const [isSidebarNeedsUpdate, setIsSidebarNeedsUpdate] = useState<boolean>(false);
  
  /*
   * Effects
   */

  useEffect(() => {
    if (currentIntentView) {
      const intentData = parseIntentData(currentIntentView, addressToPlatform);
      setIntentData(intentData);
      
      const {
        defaultPaymentMode,
        supportsSendingPaymentOnWeb,
        supportsAppclip,
      } = paymentPlatformInfo[intentData.paymentPlatform];

      const isDefaultPaymentModeQRCode = defaultPaymentMode === PaymentPlatformDefaultPaymentMode.QR_CODE;
      const isDefaultPaymentModeDesktop = defaultPaymentMode === PaymentPlatformDefaultPaymentMode.WEB_PAYMENT;
      const isDesktop = !isMobile;

      if (
        isDefaultPaymentModeQRCode
        && isDesktop
      ) {
        setViewConfig({
          showQRCodePayment: true,
          showSendPaymentOnDesktopOption: supportsSendingPaymentOnWeb,
          expandDetails: false,
          showSendPaymentButton: false,
          isConfirmationButtonAccessory: false,
        });
      } else if (
        isDefaultPaymentModeDesktop
        && isDesktop
      ) {
        setViewConfig({
          showQRCodePayment: false,
          showSendPaymentOnDesktopOption: false, // don't need to show this option if default payment mode is desktop
          expandDetails: true,
          showSendPaymentButton: true,
          isConfirmationButtonAccessory: true,
        });
      } else if (isMobile) {
        setViewConfig({
          showQRCodePayment: false,
          showSendPaymentOnDesktopOption: false,
          expandDetails: false,
          showSendPaymentButton: true,
          isConfirmationButtonAccessory: true,
        });
      }
    }
  }, [currentIntentView, addressToPlatform]);

  useEffect(() => {
    if (isSidebarInstalled && sideBarVersion && intentData?.paymentPlatform) {
      const needsUpdate = isVersionOutdated(sideBarVersion, intentData.paymentPlatform);
      setIsSidebarNeedsUpdate(needsUpdate);
    }
  }, [isSidebarInstalled, sideBarVersion, intentData?.paymentPlatform]);

  useEffect(() => {
    if (intentData && !rawPayeeDetails) {
      fetchPayeeDetails(
        intentData.depositorOnchainPayeeDetails, 
        intentData.paymentPlatform
      );
    }
  }, [intentData, fetchPayeeDetails, rawPayeeDetails]);

  useEffect(() => {
    if (intentData && rawPayeeDetails) {
      const formattedTroubleScanningQRCodeLink = paymentPlatformInfo[intentData.paymentPlatform].troubleScanningQRCodeLink(
        rawPayeeDetails, intentData.sendCurrency, intentData.amountFiatToSend
      );
      const formattedLink = paymentPlatformInfo[intentData.paymentPlatform].getFormattedSendLink(
        rawPayeeDetails, intentData.sendCurrency, intentData.amountFiatToSend
      );
      console.log(formattedTroubleScanningQRCodeLink);
      setTroubleScanningQRCodeLink(formattedTroubleScanningQRCodeLink);
      setLink(formattedLink);
    }
  }, [intentData, rawPayeeDetails]);

  useEffect(() => {
    if (intentData) {
      const { sendCurrency, amountFiatToSend } = intentData;
      const warningGetter = paymentPlatformInfo[intentData.paymentPlatform].sendPaymentWarning;
      if (warningGetter) {
        const warning = warningGetter(sendCurrency, amountFiatToSend);
        setPaymentWarning(warning);
      }
      const infoGetter = paymentPlatformInfo[intentData.paymentPlatform].sendPaymentInfo;
      if (infoGetter) {
        const info = infoGetter(sendCurrency, amountFiatToSend);
        setPaymentInfo(info);
      }
    }
  }, [intentData]);

  /*
   * Helpers
   */


  const getTitle = () => {
    return 'Complete Payment'
  }

  const renderQRCode = () => {
    if (!intentData || !link) {
      return (
        <LoadingBox>
          <ThemedText.BodySmall>
            Loading...
          </ThemedText.BodySmall>
        </LoadingBox>
      );
    }

    const { useCustomQRCode } = paymentPlatformInfo[intentData.paymentPlatform];

    if (isFetchingRawPayeeDetails) {
      return (
        <LoadingBox>
          <ThemedText.BodySmall>
            Loading...
          </ThemedText.BodySmall>
        </LoadingBox>
      );
    }

    if (useCustomQRCode || imageError) {
      return (
        <QRCode
          value={link}
          size={162}
        />
      );
    }

    return (
      <QRImage
        src={link}
        alt={`${paymentPlatformInfo[intentData.paymentPlatform].platformName} QR Code`}
        onError={() => {
          console.error('Failed to load QR code image:', link);
          setImageError(true);
        }}
      />
    );
  };


  /*
   * Component
   */

  return (
    <Container>
      <TitleContainer>
        <StyledRowBetween>
          <div style={{ flex: 0.25 }}>
            <button
              onClick={onBackClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            {getTitle()}
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.25 }}/>
        </StyledRowBetween>

        <Breadcrumb
          currentStep={BreadcrumbStep.PAYMENT}
          showExtensionStep={!isSidebarInstalled || isSidebarNeedsUpdate}
        />
      </TitleContainer>


      {viewConfig && viewConfig.showQRCodePayment && (
        <QRAndLabelContainer>
          <QRContainer>
            {renderQRCode()}
        </QRContainer>
        
        {intentData?.paymentPlatform && (
          <QRLabel>
            {troubleScanningQRCodeLink ? (
              <Link href={troubleScanningQRCodeLink || ''} target='_blank'>  
                {viewConfig.showSendPaymentOnDesktopOption ? `Pay on Desktop ↗` : `Trouble Scanning QR? ↗`}
              </Link>
            ) : (
              <LoadingRectangle />
            )}
          </QRLabel>
        )}
      </QRAndLabelContainer>
      )}
        
      {intentData && ( 
        <Details
          intentData={intentData}
          initOpen={viewConfig.expandDetails}
          payeeDetailPrefix={paymentPlatformInfo[intentData.paymentPlatform].payeeDetailPrefix}
        />
      )}

      {paymentWarning && (
        <WarningContainer>
          <WarningTextBox 
            text={paymentWarning} 
            type='warning'
            size="s"
            fontSize="16px"
          />
        </WarningContainer>
      )}

      {paymentInfo && (
        <WarningContainer>
          <WarningTextBox 
            text={paymentInfo} 
            type='info'
            size="s"
            fontSize="16px"
          />
        </WarningContainer>
      )}

      <ButtonContainer>
        {viewConfig && viewConfig.showSendPaymentButton && (
          <Button
            fullWidth
            height={48}
            onClick={() => {
              window.open(troubleScanningQRCodeLink || '', '_blank');
            }}
            disabled={!intentData}
          >
            Send Payment
          </Button>
        )}
      
        {(viewConfig && !viewConfig.isConfirmationButtonAccessory) ? (
          <Button
            fullWidth
            height={48}
            onClick={onCompleteClick}
            disabled={!intentData}
          >
            I have completed payment
          </Button>
        ) : (
          <AccessoryButton
            fullWidth
            height={48}
            borderRadius={24}
            textAlign='center'
            title="I have completed payment"
            onClick={onCompleteClick}
            disabled={!intentData}
          />
        )}
      </ButtonContainer>
    </Container>
  );
};

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;


const Container = styled.div`
  margin: auto;
  padding: 1.5rem;
  background-color: ${colors.container};
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 8px;
  display: flex;
  gap: 1.5rem;  
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  @media (min-width: 600px) {
    border-radius: 16px;
    width: 400px;
  }

  @media (max-width: 600px) {
    width: 98%;
    margin: 0 auto;
    box-sizing: border-box;
  }
`;

const TitleContainer = styled.div`
  padding: 0;
  width: 100%;
`;

const QRAndLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QRContainer = styled.div`
  padding: 1.25rem 1.25rem 1rem 1.25rem;
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 16px;
  background: #131A2A;
`;

const PulsingText = styled.span`
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const QRImage = styled.img`
  width: 192px;
  height: 192px;
`;


const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.white};
`;

const VerticalDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${colors.defaultBorderColor};
  margin: 0 auto;
`;


const QRLabel = styled.div`
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const LoadingBox = styled.div`
  width: 192px;
  height: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const WarningContainer = styled.div`
  width: 100%;
`;

const StyledRowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
  
  > div:first-child {
    padding-left: 0.5rem;
  }
  
  > div:last-child {
    padding-right: 0.5rem;
  }
`;

const LoadingRectangle = styled.div`
  width: 100%;
  height: 24px;
  background: ${colors.defaultBorderColor};
  border-radius: 4px;
  animation: pulse 1.5s infinite;
  align-self: center;    

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.6;
    }
  }
`;