import { Suspense, useCallback, useEffect, useMemo, useState, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { erc20ABI } from 'wagmi';
import { PublicKey } from "@solana/web3.js";
import { getDomainKeySync, NameRegistryState } from "@bonfida/spl-name-service";
import debounce from 'lodash/debounce';
import { Execute, GetPriceParameters, GetQuoteParameters } from '@reservoir0x/relay-sdk';

import { Button } from "@components/common/Button";
import { CustomConnectButton } from "@components/common/ConnectButton";
import { AutoColumn } from '@components/layouts/Column';
import { InputWithTokenSelector } from '@components/modals/selectors/token/InputWithTokenSelector';
import { Input } from '@components/common/Input';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { ZERO_BIGINT } from '@helpers/constants';
import { toBigInt, toTokenString, toBigIntEth } from '@helpers/unitsOld';
import {
  LoginStatus,
  SendTransactionStatus,
  Token,
  Abi,
  tokenInfo,
  TokenData
} from '@helpers/types';
import { resolveEnsName } from '@helpers/ens';
import { alchemySolanaConnection } from "index";
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useSendSettings from '@hooks/contexts/useSendSettings';
import useMediaQuery from "@hooks/useMediaQuery";

import baseSvg from '../../assets/images/base.svg';
import sepoliaSvg from '../../assets/images/sepolia.svg';
import { tokenUnits } from '@helpers/units';
import { usePrepareSendTransaction, useSendTransaction, useWaitForTransaction, usePrepareContractWrite, useContractWrite } from 'wagmi';

type RecipientAddress = {
  input: string;
  ensName: string;
  rawAddress: string;
  displayAddress: string;
  addressType: string;
};

const EMPTY_RECIPIENT_ADDRESS: RecipientAddress = {
  input: '',
  ensName: '',
  rawAddress: '',
  displayAddress: '',
  addressType: ''
};

interface TokenSendConfig {
  tokenInfo: TokenData;
  isNative: boolean;
  address: string | null;
  balance: bigint | null;
  abi: Abi | null;
  refetchBalance: (() => void) | null;
}

export const SendForm: React.FC = () => {

  SendForm.displayName = "SendForm";

  /*
   * Contexts
   */

  const { isLoggedIn, network, loginStatus, loggedInEthereumAddress } = useAccount();
  const { usdcBalance, refetchUsdcBalance, ethBalance, refetchEthBalance, cbbtcBalance, refetchCbbtcBalance } = useBalances();
  const { blockscanUrl, usdcAddress, usdcAbi } = useSmartContracts();
  const { sendToken, setSendToken } = useSendSettings();

  const tokenSendConfig: TokenSendConfig = useMemo(() => {
    switch (sendToken) {
      case Token.ETH: {
        return {
          tokenInfo: tokenInfo[Token.ETH],
          isNative: true,
          balance: ethBalance !== null ? BigInt(ethBalance.toString()) : null,
          address: null,
          abi: null,
          refetchBalance: refetchEthBalance
        };
      }
      case Token.CBBTC: {
        return {
          tokenInfo: tokenInfo[Token.CBBTC],
          isNative: false,
          balance: cbbtcBalance !== null ? BigInt(cbbtcBalance.toString()) : null,
          address: tokenInfo[Token.CBBTC].address,
          abi: null,
          refetchBalance: refetchCbbtcBalance
        };
      }
      case Token.USDC:
      default: {
        return {
          tokenInfo: tokenInfo[Token.USDC],
          isNative: false,
          balance: usdcBalance !== null ? BigInt(usdcBalance.toString()) : null,
          address: usdcAddress,
          abi: erc20ABI,
          refetchBalance: refetchUsdcBalance
        };
      }
    }
  }, [
    sendToken, 
    ethBalance, 
    usdcBalance,
    cbbtcBalance,
    usdcAddress,
    refetchEthBalance,
    refetchUsdcBalance,
    refetchCbbtcBalance
  ]);

  const currentDeviceSize = useMediaQuery();
  const isMobile = currentDeviceSize === 'mobile';

  /*
   * State
   */

  const [transactionHash, setTransactionHash] = useState<string>('');

  const [sendState, setSendState] = useState(SendTransactionStatus.DEFAULT);
  const [sendAmountInput, setSendAmountInput] = useState<string>('');

  const [resolvingEnsName, setResolvingEnsName] = useState<boolean>(false);

  const [recipientAddressInput, setRecipientAddressInput] = useState<RecipientAddress>(EMPTY_RECIPIENT_ADDRESS);
  const [isRecipientInputFocused, setIsRecipientInputFocused] = useState(false);
  const [isValidRecipientAddress, setIsValidRecipientAddress] = useState<boolean>(false);

  const [shouldConfigureEthTransferWrite, setShouldConfigureEthTransferWrite] = useState<boolean>(false);
  const [shouldConfigureErc20TransferWrite, setShouldConfigureErc20TransferWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  //
  // Raw ETH transfer
  //
  const { config: writeRawEthConfig } = usePrepareSendTransaction({
    to: recipientAddressInput.rawAddress,
    value: toBigIntEth(sendAmountInput),
    enabled: shouldConfigureEthTransferWrite,
    overrides: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  })
  const {
    data: submitRawEthResult,
    status: signRawEthTransactionStatus,
    sendTransactionAsync: writeSubmitRawEthTransferAsync
  } = useSendTransaction(writeRawEthConfig);

  const {
    status: mineRawEthTransactionStatus,
  } = useWaitForTransaction({
    hash: submitRawEthResult ? submitRawEthResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeSubmitRawEthTransferAsync successful: ', data);

      resetStateOnSuccessfulTransaction();
    },
  });

  //
  // transfer(address spender, uint256 value)
  //
  const { config: writeErc20TransferConfig } = usePrepareContractWrite({
    address: tokenSendConfig.address,
    abi: erc20ABI,
    functionName: "transfer",
    args: [
      recipientAddressInput.rawAddress,
      toBigInt(sendAmountInput, tokenSendConfig.tokenInfo.tokenDecimals),
    ],
    enabled: shouldConfigureErc20TransferWrite,
    overrides: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitErc20TransferResult,
    status: signErc20TransferTransactionStatus,
    writeAsync: writeSubmitErc20TransferAsync
  } = useContractWrite(writeErc20TransferConfig);

  const {
    status: mineErc20TransferTransactionStatus,
  } = useWaitForTransaction({
    hash: submitErc20TransferResult ? submitErc20TransferResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeSubmitErc20TransferAsync successful: ', data);

      resetStateOnSuccessfulTransaction();
    },
  });

  /*
   * Handlers
   */

  const handleSendAmountInputChange = async (value: string) => {
    validateAndSetQuote(value);
  };

  const handleRecipientInputChange = async (value: string) => {
    validateAndSetAddress(value);
  };

  /*
   * Hooks
   */

  useEffect(() => {
    // If user didn't input an amount, mark MISSING_AMOUNTS
    if (!sendAmountInput) {
      setSendState(SendTransactionStatus.MISSING_AMOUNTS);
      return;
    }

    // Must have a loaded balance to proceed
    const tokenBalanceLoaded = tokenSendConfig.balance !== null;
    if (!tokenBalanceLoaded) {
      setSendState(SendTransactionStatus.MISSING_AMOUNTS);
      return;
    }

    const sendAmountBI = toBigInt(sendAmountInput);
    const isSendAmountGreaterThanBalance = sendAmountBI > (tokenSendConfig.balance ?? ZERO_BIGINT);
    if (isSendAmountGreaterThanBalance) {
      setSendState(SendTransactionStatus.INSUFFICIENT_BALANCE);
      return;
    }
    if (!recipientAddressInput.input) {
      setSendState(SendTransactionStatus.DEFAULT);
      return;
    }
    if (!isValidRecipientAddress) {
      setSendState(SendTransactionStatus.INVALID_RECIPIENT_ADDRESS);
      return;
    }

    /*
     * If we get here, the user:
     *  1) has valid sendAmount
     *  2) does not exceed their balance
     *  3) has valid recipient address
     */
    if (tokenSendConfig.isNative) {
      // e.g. ETH
      const signingNativeTx = signRawEthTransactionStatus === 'loading';
      const miningNativeTx = mineRawEthTransactionStatus === 'loading';
      if (signingNativeTx) {
        setSendState(SendTransactionStatus.TRANSACTION_SIGNING);
      } else if (miningNativeTx) {
        setSendState(SendTransactionStatus.TRANSACTION_MINING);
      } else {
        setSendState(SendTransactionStatus.VALID_FOR_ETH_TRANSFER);
      }
    } else {
      const signingErc20SendTransaction = signErc20TransferTransactionStatus === 'loading';
      const miningErc20SendTransaction = mineErc20TransferTransactionStatus === 'loading';

      if (signingErc20SendTransaction) {
        setSendState(SendTransactionStatus.TRANSACTION_SIGNING);
      } else if (miningErc20SendTransaction) {
        setSendState(SendTransactionStatus.TRANSACTION_MINING);
      } else {
        setSendState(SendTransactionStatus.VALID_FOR_ERC20_TRANSFER);
      }
    }
  }, [
    sendAmountInput,
    tokenSendConfig,
    recipientAddressInput,
    isValidRecipientAddress,
    signRawEthTransactionStatus,
    mineRawEthTransactionStatus,
    signErc20TransferTransactionStatus,
    mineErc20TransferTransactionStatus
  ]);

  useEffect(() => {
    if (loginStatus === LoginStatus.LOGGED_OUT) {
      resetStateOnSuccessfulTransaction();

      if (transactionHash) {
        setTransactionHash('');
      };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  useEffect(() => {    
    setShouldConfigureErc20TransferWrite(sendState === SendTransactionStatus.VALID_FOR_ERC20_TRANSFER);

    setShouldConfigureEthTransferWrite(sendState === SendTransactionStatus.VALID_FOR_ETH_TRANSFER);
  }, [sendState]);

  useEffect(() => {
    if (submitErc20TransferResult?.hash) {
      setTransactionHash(submitErc20TransferResult.hash);
    };
    if (submitRawEthResult?.hash) {
      setTransactionHash(submitRawEthResult.hash);
    };
  }, [submitErc20TransferResult, submitRawEthResult])

  useEffect(() => {
    let recipientAddressForUpdatedQuote = recipientAddressInput.rawAddress;
    
    const sendTokenAddressTypeForRecipient = sendToken === Token.SOL ? 'sol' : 'eth';
    if (sendTokenAddressTypeForRecipient !== recipientAddressInput.addressType) {
      recipientAddressForUpdatedQuote = '';

      setRecipientAddressInput(EMPTY_RECIPIENT_ADDRESS);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendToken]);
  
  /*
   * Helpers
   */

  function resetStateOnInputChanges() {
    if (transactionHash) {
      setTransactionHash('');
    };

    if (sendState === SendTransactionStatus.TRANSACTION_SUCCEEDED) {
      setSendState(SendTransactionStatus.DEFAULT);
    }
  }

  function resetStateOnSuccessfulTransaction() {
    setSendAmountInput('');
    setRecipientAddressInput(EMPTY_RECIPIENT_ADDRESS);

    tokenSendConfig.refetchBalance?.();
  };

  const validateAndSetQuote = async (value: string) => {
    if (value === "") {
      setSendAmountInput("");
    } else if (value === "0") {
      setSendAmountInput("0");
    } else if (value === ".") {
      setSendAmountInput("0.");
    } else {
      setSendAmountInput(value);
    }
  }

  const validateAndSetAddress = async (recipientAddress: string) => {
    resetStateOnInputChanges();

    let rawAddress = '';
    let ensName = '';
    let displayAddress = '';
    let isValidAddress = false;

    const isNetworkSolana = sendToken === Token.SOL;

    setRecipientAddressInput({
      input: recipientAddress,
      ensName,
      rawAddress,
      displayAddress,
      addressType: isNetworkSolana ? 'sol' : 'eth'
    });
  
    if (isNetworkSolana) {
      if (recipientAddress.endsWith('.sol')) {
        setResolvingEnsName(true);
        const { pubkey: recordKey } = getDomainKeySync(recipientAddress);
        const { registry } = await NameRegistryState.retrieve(alchemySolanaConnection, recordKey);
        const { owner } = registry;

        if (owner) {
          rawAddress = owner.toString();
          displayAddress = recipientAddress;
          isValidAddress = true;
        }

        setResolvingEnsName(false);
      } else if (recipientAddress.length >= 32 && recipientAddress.length <= 44 && /^[1-9A-HJ-NP-Za-km-z]+$/.test(recipientAddress)) {
        try {
          const publicKey = new PublicKey(recipientAddress);
          const validSolanaAddress = await PublicKey.isOnCurve(publicKey);
          if (validSolanaAddress) {
            rawAddress = recipientAddress;
            displayAddress = recipientAddress;
            isValidAddress = true;
          }
        } catch {
          console.log('Invalid Solana address provided');
        }
      }
    } else {
      if (recipientAddress.endsWith('.eth') || recipientAddress.endsWith('.xyz')) {
        setResolvingEnsName(true);
        ensName = recipientAddress;
        const resolvedAddress = await resolveEnsName(recipientAddress);
        if (resolvedAddress) {
          rawAddress = resolvedAddress;
          displayAddress = resolvedAddress;
          isValidAddress = true;
        }
        setResolvingEnsName(false);
      } else if (recipientAddress.startsWith('0x') && recipientAddress.length === 42) {
        rawAddress = recipientAddress;
        displayAddress = recipientAddress;
        isValidAddress = true;
      };
    }

    setRecipientAddressInput(prevState => ({
      ...prevState,
      ensName: ensName,
      rawAddress: rawAddress,
      displayAddress: displayAddress,
    }));

    setIsValidRecipientAddress(isValidAddress);
  }

  /*
   * Other Helpers
   */

  const recipientPlaceholderLabel = useMemo(() => {
    if (sendToken === Token.SOL) {
      return "Wallet address or SNS name";
    } else {
      return "Wallet address or ENS name";
    }
  }, [sendToken]);

  function isValidSendAmountInput(value: string) {
    const isValidUsdcInput = /^-?\d*(\.\d{0,6})?$/.test(value);
    // const isValidEthInput = /^-?\d*(\.\d{0,18})?$/.test(value);
    
    return parseFloat(value) >= 0 && isValidUsdcInput;
  };

  const tokenBalanceLabel = useMemo(() => {
    if (isLoggedIn && tokenSendConfig.balance !== null) {
      console.log('tokenSendConfig: ', tokenSendConfig);
      // Here we can format with your token decimals if needed
      return `Balance: ${toTokenString(tokenSendConfig.balance, tokenSendConfig.tokenInfo.tokenDecimals)}`;
    }
    return '';
  }, [isLoggedIn, tokenSendConfig]);

  const ctaOnClick = async () => {
    switch (sendState) {
      case SendTransactionStatus.VALID_FOR_ERC20_TRANSFER:
        try {
          await writeSubmitErc20TransferAsync?.();
        } catch (error) {
          console.log('writeSubmitErc20TransferAsync failed: ', error);
        }
        break;
      case SendTransactionStatus.VALID_FOR_ETH_TRANSFER:
        try {
          await writeSubmitRawEthTransferAsync?.();
        } catch (error) {
          console.log('writeSubmitRawEthTransferAsync failed: ', error);
        }
        break;

      default:
        break;
    }
  };

  const ctaDisabled = (): boolean => {
    if (resolvingEnsName) {
      return true;
    }

    switch (sendState) {
      case SendTransactionStatus.DEFAULT:
      case SendTransactionStatus.INSUFFICIENT_BALANCE:
      case SendTransactionStatus.INVALID_RECIPIENT_ADDRESS:
      case SendTransactionStatus.MISSING_AMOUNTS:
      case SendTransactionStatus.TRANSACTION_SIGNING:
      case SendTransactionStatus.TRANSACTION_MINING:
        return true;

      case SendTransactionStatus.VALID_FOR_ERC20_TRANSFER:
      case SendTransactionStatus.VALID_FOR_ETH_TRANSFER:
      case SendTransactionStatus.VALID_FOR_SOL_TRANSFER:
      case SendTransactionStatus.TRANSACTION_SUCCEEDED:
      default:
        return false;
    }
  };

  const ctaLoading = (): boolean => {
    // if (resolvingEnsName) {
    //   return true;
    // }

    switch (sendState) {
      case SendTransactionStatus.TRANSACTION_SIGNING:
      case SendTransactionStatus.TRANSACTION_MINING:
        return loginStatus === LoginStatus.AUTHENTICATED;

      default:
        return false;
    }
  };

  const ctaText = (): string => {
    if (resolvingEnsName) {
      return 'Resolving To Address';
    }

    switch (sendState) {
      case SendTransactionStatus.INVALID_RECIPIENT_ADDRESS:
        return 'Invalid recipient address';

      case SendTransactionStatus.MISSING_AMOUNTS:
        return 'Input send amount';
      
        case SendTransactionStatus.INSUFFICIENT_BALANCE: {
          const readableBalance = tokenSendConfig.balance
            ? toTokenString(tokenSendConfig.balance, tokenSendConfig.tokenInfo.tokenDecimals) 
            : '0';
          return `Insufficient ${tokenSendConfig.tokenInfo.ticker} balance: ${readableBalance}`;
        }

      case SendTransactionStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case SendTransactionStatus.TRANSACTION_MINING:
        return 'Transaction Mining';

      case SendTransactionStatus.VALID_FOR_ERC20_TRANSFER:
        return 'Send';

      case SendTransactionStatus.VALID_FOR_ETH_TRANSFER:
        return 'Send';

      case SendTransactionStatus.TRANSACTION_SUCCEEDED:
        return 'Send';

      case SendTransactionStatus.DEFAULT:
      default:
        return 'Input recipient address';
    }
  };

  const recipientInputText = (): string => {
    if (isRecipientInputFocused) {
      return recipientAddressInput.input;
    } else {
      if (recipientAddressInput.ensName) {
        return recipientAddressInput.ensName;
      } else if (recipientAddressInput.displayAddress) {
        return recipientAddressInput.displayAddress;
      } else {
        return recipientAddressInput.input;
      }
    }
  };

  const networkSvg = (): string => {
    if (network === 'sepolia') {
      return sepoliaSvg;
    } else {
      return baseSvg;
    }
  };

  const networkName = (): string => {
    if (network === 'sepolia') {
      return 'Sepolia';
    } else {
      return 'Base';
    }
  };

  /*
   * Component
   */

  return (
    <Suspense>
      <Wrapper>
        <SendFormContainer>
          {!isMobile ? (
            <TitleContainer>
              <ThemedText.HeadlineMedium>
                Send
              </ThemedText.HeadlineMedium>
            </TitleContainer>
          ) : (
            <TitleContainer>
              <ThemedText.HeadlineSmall>
                Send
              </ThemedText.HeadlineSmall>
            </TitleContainer>
          )}

          <MainContentWrapper>
            <NetworkFromAndToContainer>
              <NetworkFromAndToInnerContainer>
                <FromNetworkContainer>
                  <NetworkLogoAndNameContainer>
                    <NetworkSvg src={networkSvg()} />

                    <NetworkNameContainer>
                      <NetworkHeader>
                        {'From'}
                      </NetworkHeader>

                      <NetworkNameLabel>
                        {networkName()}
                      </NetworkNameLabel>
                    </NetworkNameContainer>
                  </NetworkLogoAndNameContainer>
                </FromNetworkContainer>

                <FromNetworkContainer>
                  <NetworkLogoAndNameContainer>
                    <NetworkSvg src={networkSvg()} />

                    <NetworkNameContainer>
                      <NetworkHeader>
                        {'To'}
                      </NetworkHeader>

                      <NetworkNameLabel>
                        {networkName()}
                      </NetworkNameLabel>
                    </NetworkNameContainer>
                  </NetworkLogoAndNameContainer>
                </FromNetworkContainer>
              </NetworkFromAndToInnerContainer>
            </NetworkFromAndToContainer>

            <InputWithTokenSelector
              label="Send"
              name={`SendAmount`}
              value={sendAmountInput}
              onChange={(e) => handleSendAmountInputChange(e.currentTarget.value)}
              type="number"
              inputLabel={sendToken}
              placeholder="0"
              accessoryLabel={tokenBalanceLabel}
              enableMax={true}
              maxButtonOnClick={() => {
                if (tokenSendConfig.balance) {
                  handleSendAmountInputChange(toTokenString(tokenSendConfig.balance, tokenSendConfig.tokenInfo.tokenDecimals));
                }
              }}
              hasSelector={true}
              selectedToken={sendToken}
              setSelectedToken={setSendToken}
              onlyShowCurrentNetwork={true}
            />

            <Input
              label="To"
              name={`to`}
              value={recipientInputText()}
              onChange={(e) => {handleRecipientInputChange(e.currentTarget.value)}}
              onFocus={() => setIsRecipientInputFocused(true)}
              onBlur={() => setIsRecipientInputFocused(false)}
              type="string"
              placeholder={recipientPlaceholderLabel}
              valueFontSize="16px"
            />

            <ButtonContainer>
              {!isLoggedIn ? (
                <CustomConnectButton
                  fullWidth={true}
                />
              ) : (
                <Button
                  fullWidth={true}
                  disabled={ctaDisabled()}
                  loading={ctaLoading()}
                  onClick={async () => {
                    ctaOnClick();
                  }}>
                  { ctaText() }
                </Button>
               )}
            </ButtonContainer>

            {transactionHash?.length ? (
              <LinkContainer>
                <Link
                  disabled={!transactionHash}
                  href={`${blockscanUrl}/tx/${transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer">
                    <ThemedText.LabelSmall textAlign="center">
                      View on Explorer ↗
                    </ThemedText.LabelSmall>
                </Link>
              </LinkContainer>
            ) : null}
          </MainContentWrapper>
        </SendFormContainer>
      </Wrapper>
    </Suspense>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 484px;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin: auto;
    width: 98%;
  }
`;

const SendFormContainer = styled(AutoColumn)`
  padding: 1rem;
  gap: 1rem;
  background-color: ${colors.container};
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  justify-content: flex-start;
`;

const TitleContainer = styled.div`
  display: flex;
  margin: 0rem 0.75rem;
  justify-content: space-between;
  align-items: center;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-self: center;
  border-radius: 4px;
  justify-content: center;
`;

const NetworkFromAndToContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NetworkFromAndToInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const FromNetworkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NetworkLogoAndNameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  background: ${colors.selectorColor};
  padding: 1.1rem 1rem;
`;

const NetworkNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const NetworkHeader = styled.div`
  font-size: 14px;
  color: #CED4DA;
`;

const NetworkNameLabel = styled.div`
  font-size: 16px;
  color: #6C757D;
`;

const NetworkSvg = styled.img`
  width: 32px;
  height: 32px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0 2.25rem;
`;

interface LinkProps {
  disabled?: boolean;
}

const Link = styled.a<LinkProps>`
  white-space: pre;
  display: inline-block;
  color: #1F95E2;
  text-decoration: none;
  padding: 0.75rem 1rem 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  flex: 1;

  &:hover {
    text-decoration: ${({ disabled }) => disabled ? 'none' : 'underline'};
  }

  ${({ disabled }) => 
    disabled && `
      color: gray;
      pointer-events: none;
      cursor: default;
  `}
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

export default SendForm;