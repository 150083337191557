import React from 'react';
import styled from 'styled-components/macro';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import Link from '@mui/material/Link';
import { Trash2 } from 'react-feather';

import useQuery from '@hooks/useQuery';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { tokenUnitsToReadable } from '@helpers/units';
import { tokenInfo, TokenType, Token } from '@helpers/types';

interface ReferrerInfoProps {
  className?: string;
  onCloseClick: () => void;
}

export const ReferrerInfo: React.FC<ReferrerInfoProps> = ({ 
  className,
  onCloseClick
}) => {
  
  ReferrerInfo.displayName = 'ReferrerInfo';

  /*
   * Hooks
   */

  const { queryParams } = useQuery();

  /*
   * State
   */
  const { 
    REFERRER: referrer, 
    REFERRER_LOGO: referrerLogo, 
    REFERRER_TO_TOKEN: toToken, 
    REFERRER_AMOUNT_USDC: amountUsdc, 
    REFERRER_RECIPIENT_ADDRESS: recipientAddress 
  } = queryParams;

  const { blockscanUrl, solanaBlockscanUrl, ethBlockscanUrl, polygonBlockscanUrl } = useSmartContracts();

  if (!referrer) return null;

  /*
   * Helpers
   */


  const getRequestLabel = () => {
    let recipientAddressLabel: React.ReactNode = '';
    let tokenLabel = '';
    let tokenChain = '';

    // Set token info first
    if (amountUsdc) {
      tokenLabel = `${tokenUnitsToReadable(amountUsdc, 6, 2)} USDC`;
      tokenChain = 'Base';
    } else if (toToken) {
      tokenLabel = `${tokenInfo[toToken as TokenType]?.ticker}`;
      tokenChain = tokenInfo[toToken as TokenType]?.chainName;
    } else {
      tokenLabel = 'funds';
      tokenChain = 'Base';
    }

    if (recipientAddress) {
      let currentBlockscanUrl = '';
      
      switch (tokenChain) {
        case 'Solana':
          currentBlockscanUrl = solanaBlockscanUrl ?? '';
          break;
        case 'Ethereum':
          currentBlockscanUrl = ethBlockscanUrl ?? '';
          break;
        case 'Polygon':
          currentBlockscanUrl = polygonBlockscanUrl ?? '';
          break;
        default:
          currentBlockscanUrl = blockscanUrl ?? '';
          break;
      }

      if (currentBlockscanUrl) {
        recipientAddressLabel = (
          <span>to <Link href={`${currentBlockscanUrl}/address/${recipientAddress}`} target="_blank">
            {recipientAddress.slice(0, 6)}
          </Link></span>
        );
      }
    }

    return (
      <RequestLabel>
        {referrer} is requesting {tokenLabel} {recipientAddressLabel} on {tokenChain}. Choose your preferred currency and payment method.
      </RequestLabel>
    );
  };

  const getTokenIcon = () => {
    if (amountUsdc) {
      return tokenInfo[Token.USDC].icon;
    } else if (toToken) {
      return tokenInfo[toToken as TokenType]?.icon;
    }
    return `${process.env.PUBLIC_URL}/logo512.png`;
  };

  const renderLogos = () => {
    if (!referrerLogo) {
      return (  
        <LogoContainer>
          <IconStack>
            <ReferrerIcon src={`${process.env.PUBLIC_URL}/logo512.png`} alt="ZKP2P logo" />
            <TokenIcon src={getTokenIcon()} alt="Token icon" />
          </IconStack>
        </LogoContainer>
      );
    } else {
      return (
        <LogoContainer>
          <IconStack>
            <ReferrerIcon src={referrerLogo} alt={referrer} />
            <TokenIcon src={getTokenIcon()} alt="Token icon" />
          </IconStack>
        </LogoContainer>
      );
    }
  };

  /*
   * Render
   */

  return (
    <Container className={className}>
      <ContentWrapper>
        {renderLogos()}
        <TextContainer>
          <ThemedText.SubHeader>
            {getRequestLabel()}
          </ThemedText.SubHeader>
        </TextContainer>
        <CloseButton onClick={onCloseClick}>
          <Trash2 size={16} />
        </CloseButton>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  background-color: ${colors.container};
  border-radius: 16px;
  border: 1px solid ${colors.buttonDefault};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconStack = styled.div`
  position: relative;
  width: 54px;
  height: 32px;
`;

const ReferrerIcon = styled.img`
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  z-index: 1;
`;

const TokenIcon = styled.img`
  position: absolute;
  left: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 2;
`;

const TextContainer = styled.div`
  text-align: left;
  flex: 1;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 1px;
  color: ${colors.lightGrayText};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${colors.darkText};
  }
`;

const RequestLabel = styled.div`  font-size: 16px;
  line-height: 1.5;
  color: ${colors.darkText};
`;

export default ReferrerInfo; 
