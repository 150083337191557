import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { ArrowLeft, Link2 } from 'react-feather';
import QRCode from 'react-qr-code';
import { BigNumber } from 'ethers';

import { 
  ProofGenerationStatus, 
  ProofGenerationStatusType, 
  ReclaimProofErrorType, 
  ReclaimProofError
} from '@helpers/types';
import { Button } from '@components/common/Button';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { PaymentPlatformType } from '@helpers/types';
import Spinner from '@components/common/Spinner';
import { AccessoryButton } from '@components/common/AccessoryButton';

import { providers } from '@helpers/providers';
import { parseAppClipProof } from '@helpers/types';
import reclaimSvg from '../../../assets/images/reclaim.svg'

import { Proof } from '@helpers/types';
import { ReclaimProofRequest, Proof as ReclaimProof } from '@reclaimprotocol/js-sdk';

import useQuery from '@hooks/useQuery';
import useAccount from '@hooks/contexts/useAccount';
import { ProofDetails } from './ProofDetails';
import useSmartContracts from "@hooks/contexts/useSmartContracts";
import { WarningTextBox } from '@components/common/WarningTextBox';
import useMediaQuery from "@hooks/useMediaQuery"
import { ParsedIntentData, parseIntentData } from '@helpers/intentHelper';
import useOnRamperIntents from '@hooks/contexts/useOnRamperIntents';
import { ProvePayment } from '@components/modals/ProvePayment';
import { ParsedQuoteData } from '@hooks/useRelayBridge';
import { generateFakeVenmoPaymentProof } from '@helpers/types/proxyProof';
import { rollbar } from '@helpers/rollbar';
import { BreadcrumbStep } from '@components/common/Breadcrumb';

interface ReclaimProofFormProps {
  intentHash: string;
  paymentPlatform: PaymentPlatformType;
  paymentProof: Proof | null;
  setPaymentProof: (proof: Proof | null) => void;
  proofGenerationStatus: ProofGenerationStatusType;
  setProofGenerationStatus: (status: ProofGenerationStatusType) => void;
  handleCompleteOrderClick: () => void;
  onProofGenCompletion: () => void;
  completeOrderTransactionSigningStatus: string;
  completeOrderTransactionMiningStatus: string;
  completeOrderTransactionHash: string;
  handleUseExtensionFlowClick: () => void;
  bridgingNeeded: boolean;
  quoteData: ParsedQuoteData | null;
  bridgeTransactions: {
    txHash: string;
    chainId: number;
  }[] | null;
  handleSubmitSwapClick: () => void;
  simulationErrorMessage: string | null;
  setTitle: (title: string) => void;
  setBreadcrumbStep: (step: BreadcrumbStep) => void;
}


export const ReclaimProofForm: React.FC<ReclaimProofFormProps> = ({
  intentHash,
  paymentPlatform,
  paymentProof,
  setPaymentProof,
  proofGenerationStatus,
  setProofGenerationStatus,
  handleCompleteOrderClick,
  onProofGenCompletion,
  completeOrderTransactionSigningStatus,
  completeOrderTransactionMiningStatus,
  completeOrderTransactionHash,
  handleUseExtensionFlowClick,
  bridgingNeeded,
  quoteData,
  bridgeTransactions,
  handleSubmitSwapClick,  
  simulationErrorMessage,
  setTitle,
  setBreadcrumbStep
}) => {

  const RECLAIM_APP_ID = process.env.RECLAIM_APP_ID || '';
  const RECLAIM_APP_SECRET = process.env.RECLAIM_APP_SECRET || '';

  /*
   * Context
   */
  
  const currentDeviceSize = useMediaQuery();
  const isMobile = currentDeviceSize === 'mobile';

  const { navigateWithQuery } = useQuery();
  const { loggedInEthereumAddress } = useAccount();
  const { blockscanUrl, addressToPlatform } = useSmartContracts();
  const { currentIntentView } = useOnRamperIntents();
  
  /*
   * State
   */

  const [intentData, setIntentData] = useState<ParsedIntentData | null>(null);
  const [reclaimProofRequest, setReclaimProofRequest] = useState<ReclaimProofRequest | null>(null);
  const [reclaimRequestURL, setReclaimRequestURL] = useState<string | null>(null);
  const [error, setError] = useState<ReclaimProofErrorType | null>(null);
  const [reclaimStatusUrl, setReclaimStatusUrl] = useState<string | null>(null);
      
  /*
   * Effects
   */

  useEffect(() => {
    setTitle('Verify Payment');
    setBreadcrumbStep(BreadcrumbStep.VERIFY);
  }, []);

  useEffect(() => {
    const getIntentData = async () => {
      if (currentIntentView) {
        const intentData = parseIntentData(currentIntentView, addressToPlatform);
        setIntentData(intentData);
      }
    }

    getIntentData();
  }, [currentIntentView, addressToPlatform]);

  useEffect(() => {
    const getReclaimProofRequest = async () => {
      const shouldGenerateQR = (proofGenerationStatus === ProofGenerationStatus.NOT_STARTED || !reclaimRequestURL);
      if (paymentPlatform && loggedInEthereumAddress && shouldGenerateQR) {
        try {
          setProofGenerationStatus(ProofGenerationStatus.REQUESTING_PROOF);
          
          const _reclaimProofRequest = await ReclaimProofRequest.init(
            RECLAIM_APP_ID, 
            RECLAIM_APP_SECRET,   
            providers[paymentPlatform].providerIds[0]   
          );
          _reclaimProofRequest.addContext(
            loggedInEthereumAddress,
            BigNumber.from(intentHash).toString()
          );
          const requestUrl = await _reclaimProofRequest.getRequestUrl();
          const statusUrl = await _reclaimProofRequest.getStatusUrl();

          setReclaimRequestURL(requestUrl);
          setReclaimStatusUrl(statusUrl);
          setReclaimProofRequest(_reclaimProofRequest);
        } catch (error) {
          console.error('Error getting verification request', error);
          setProofGenerationStatus(ProofGenerationStatus.REQUESTING_PROOF_FAILED);
          setError(ReclaimProofError.FAILED_TO_GENERATE_QR)
        }
      }
    }

    getReclaimProofRequest();
  }, [paymentPlatform, loggedInEthereumAddress, reclaimRequestURL]);

  // Poll status URL for proof generation
  useEffect(() => {
    if (!reclaimStatusUrl || proofGenerationStatus !== ProofGenerationStatus.REQUESTING_PROOF_SUCCESS) return;

    const pollStatusUrl = async () => {
      try {
        const response = await fetch(reclaimStatusUrl);
        const data = await response.json();
        console.log('data', data);
        if (data.session.statusV2 === 'USER_INIT_VERIFICATION') {
          setProofGenerationStatus(ProofGenerationStatus.GENERATING_PROOF);
        }
      } catch (error) {
        console.error('Error polling status URL:', error);
      }
    };

    const intervalId = setInterval(pollStatusUrl, 2000);

    return () => clearInterval(intervalId);
  }, [reclaimStatusUrl, proofGenerationStatus]);

  useEffect(() => {
    if (reclaimProofRequest) {
      setProofGenerationStatus(ProofGenerationStatus.REQUESTING_PROOF_SUCCESS);

      // const getFakeProof = async () => {
      //   // Add artificial delay to simulate proof generation
      //   await new Promise(resolve => setTimeout(resolve, 5000));

      //   console.log('intentData', intentData);
      //   const paymentTimestamp = Number(intentData?.intentTimestamp) + 30;
      //   const date = new Date(paymentTimestamp * 1000);
      //   const formattedDate = date.toISOString().slice(0, 19);
      //   console.log('formattedDate', formattedDate);
      //   const paymentId = Math.floor(Math.random() * 9000000000000000000 + 1000000000000000000).toString();
      //   const fakeProof = await generateFakeVenmoPaymentProof(BigNumber.from(intentHash).toString(), '1.00', formattedDate, '1557532678029312858', paymentId);
      //   console.log('fakeProof', fakeProof);

      //   setPaymentProof(fakeProof);
      // }

      // getFakeProof();

      reclaimProofRequest.startSession({
        onSuccess: (proof) => {
          console.log('Verification success', proof);

          const parsedProof = parseAppClipProof(proof);
          
          setPaymentProof(parsedProof);
        },
        onError: (error) => {
          console.error('Verification failed', error);

          if (proofGenerationStatus === ProofGenerationStatus.GENERATING_PROOF) {
            setProofGenerationStatus(ProofGenerationStatus.ERROR_FAILED_TO_PROVE);
            setError(ReclaimProofError.FAILED_TO_PROVE);
          }

          // Log proof gen failures in Rollbar
          rollbar.error('Error generating Reclaim proof', {
            error,
            paymentPlatform,
            intentHash
          });
        },
      });
    }
  }, [reclaimProofRequest, intentData]);

  useEffect(() => {
    if (proofGenerationStatus === ProofGenerationStatus.TRANSACTION_SIMULATION_FAILED) {
      setError(ReclaimProofError.WRONG_PAYMENT_SELECTED);
    }
  }, [proofGenerationStatus]);

  /*
   * Handlers
   */


  const isCtaLoading = useMemo(() => {
    return [
      ProofGenerationStatus.NOT_STARTED,
      ProofGenerationStatus.REQUESTING_PROOF,
      ProofGenerationStatus.TRANSACTION_LOADING,
      ProofGenerationStatus.TRANSACTION_MINING,
    ].includes(proofGenerationStatus);
  }, [proofGenerationStatus]);


  const shouldShowQR = useMemo(() => {
    return [
      ProofGenerationStatus.NOT_STARTED,
      ProofGenerationStatus.REQUESTING_PROOF,
      ProofGenerationStatus.REQUESTING_PROOF_SUCCESS,
      ProofGenerationStatus.GENERATING_PROOF,
      ProofGenerationStatus.TRANSACTION_CONFIGURED,
      ProofGenerationStatus.TRANSACTION_SIMULATION_FAILED,
      ProofGenerationStatus.TRANSACTION_LOADING,
      ProofGenerationStatus.TRANSACTION_MINING,
      ProofGenerationStatus.ERROR_FAILED_TO_PROVE,
      ProofGenerationStatus.TRANSACTION_FAILED,
    ].includes(proofGenerationStatus);
  }, [proofGenerationStatus]);

  const getErrorMessage = () => {
    switch (error) {
      case ReclaimProofError.FAILED_TO_GENERATE_QR:
        if (isMobile) {
          return 'Failed to generate link. Please continue on desktop.';
        }
        return 'Failed to generate QR code. Please continue on desktop.';
      case ReclaimProofError.FAILED_TO_PROVE:
        return 'Failed to Prove. Please continue on desktop.';
      case ReclaimProofError.WRONG_PAYMENT_SELECTED:
        return 'Proof generation successful, but transaction simulation failed. Maybe the proved payment is not valid. Please select the right payment by continuing on desktop.';
      default:
        return 'Error generating proof. Please continue on desktop.';
    }
  }

  /*
   * Render
   */

  const renderQRCode = () => {
    if (proofGenerationStatus === ProofGenerationStatus.REQUESTING_PROOF_FAILED) {
      return (
        <QRErrorBox>
          <StyledLink2Icon />
          <ThemedText.BodySmall>
            Failed to generate QR
          </ThemedText.BodySmall>
        </QRErrorBox>
      );
    }

    if (!reclaimRequestURL) {
      return (
        <QRLoadingBox>
          <ThemedText.BodySmall>
            Generating QR code...
          </ThemedText.BodySmall>
        </QRLoadingBox>
      );
    }

    return (
      <QRCode value={reclaimRequestURL} size={162}/>
    );
  };

  
  return (
    <Container>
      {!isMobile && shouldShowQR && (
        <QRAndLabelContainer>
          {renderQRCode()}
          <PoweredByContainer>
            <PoweredByText>Powered by</PoweredByText>
            <ReclaimLogo src={reclaimSvg} alt="Reclaim Protocol" />
          </PoweredByContainer>
        </QRAndLabelContainer>
      )}
      
      {error && (
        <ErrorMessageContainer>
          <WarningTextBox text={getErrorMessage()} type="error" />
        </ErrorMessageContainer>
      )}

      {error === ReclaimProofError.WRONG_PAYMENT_SELECTED && intentData && (
        <ProofDetailsContainer>
          <ProofDetails
            isLoading={false}
            proof={paymentProof}
            paymentPlatform={paymentPlatform}
            proofErrored={false}    // we want to show the proof details even if the proof is not valid
            intentData={intentData}
          />
        </ProofDetailsContainer>
      )}

      <ProvePayment
        title={"Complete Order"}
        proof={JSON.stringify(paymentProof) || ''}
        onBackClick={() => navigateWithQuery('/send')}
        status={proofGenerationStatus}
        platform={paymentPlatform}
        buttonTitle={"Complete Order"}
        submitTransactionStatus={completeOrderTransactionSigningStatus}
        isSubmitMining={completeOrderTransactionMiningStatus === 'loading'}
        transactionAddress={completeOrderTransactionHash}
        setProofGenStatus={setProofGenerationStatus}
        handleSubmitVerificationClick={handleCompleteOrderClick}
        onProofGenCompletion={onProofGenCompletion}
        displayType="page"
        isAppclipFlow={true}
        appclipRequestURL={reclaimRequestURL || ''}
        provingFailureErrorCode={null}
        bridgingNeeded={bridgingNeeded}
        quoteData={quoteData}
        bridgeTransactions={bridgeTransactions}
        handleSubmitSwapClick={handleSubmitSwapClick}
        simulationErrorMessage={simulationErrorMessage}
      />

      {!isMobile && shouldShowQR && (
        <AccessoryButtonContainer>
          <AccessoryButton
            onClick={handleUseExtensionFlowClick}
            title="Or Continue on Desktop"
            fullWidth={true}
            textAlign="center"
            borderRadius={24}
          />
        </AccessoryButtonContainer>
      )}
    </Container>
  );
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;

  @media (min-width: 600px) {
    min-width: 380px;
  }
`;

const QRAndLabelContainer = styled.div`
  padding: 1.25rem 1.25rem 1rem 1.25rem;
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 16px;
  background: #131A2A;
`;

const QRBox = styled.div`
  width: 162px;
  height: 162px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const pulse = keyframes`
  0% { transform: scale(1); opacity: 0.3; }
  50% { transform: scale(1.1); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.3; }
`;

const QRLoadingBox = styled(QRBox)`
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const QRErrorBox = styled(QRBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: ${colors.buttonDisabled};
  background-color: ${colors.inputDefaultColor};
`;

const StyledLink2Icon = styled(Link2)`
  width: 48px;
  height: 48px;
  opacity: 0.5;
`;

const ProofDetailsContainer = styled.div`
  width: 100%;
`;

const ButtonAndErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`;

const PoweredByContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  gap: 5px;
`;

const PoweredByText = styled.div`
  font-size: 12px;
  text-align: top;
  padding-top: 2px;
  line-height: 1.5;
  color: #FFF;
`;

const ReclaimLogo = styled.img`
  height: 20px;
  vertical-align: middle;
`;

const Link = styled.a`
  white-space: pre;
  display: inline-block;
  color: #1F95E2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ErrorMessageContainer = styled.div`
  width: 100%;
  max-width: 360px;
`;

const AccessoryButtonContainer = styled.div`
  width: 100%;
`;