import { useState, useCallback } from "react";
import { QuoteMinFiatForTokenRequest, QuoteResponse } from "@helpers/types";

const API_URL = process.env.CURATOR_API_URL || "";

export default function useQuoteMinFiatForToken() {
  /**
   * State
   */
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<QuoteResponse | null>(null);

  /**
   * Fetch
   */
  const fetchQuote = useCallback(async (requestData: QuoteMinFiatForTokenRequest) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/v1/quote/exact-token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to fetch quoteMinFiatForToken:", errorText);
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const jsonResponse = await response.json() as QuoteResponse;

      setData(jsonResponse);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchQuote
  };
}
